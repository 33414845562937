import { Grid, TextField, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import Modal from "components/modal/Modal";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import UserService from "services/userService";

const RegisterPoint = () => {
  const intl = useIntl();

  const [couponNumber, setCouponNumber] = useState("");
  const [isError, setIsError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const notices: string[] = [];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    const upperCasedValue = value.toUpperCase();
    setIsError(false);
    setCouponNumber(upperCasedValue);
  };

  const getHeader = () => {
    return (
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {intl.formatMessage({ id: "point.coupon_registration" })}
      </Typography>
    );
  };

  const register = async () => {
    try {
      await UserService.registerPoint(couponNumber);
      setCouponNumber("");
      setModalVisible(true);
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "point.coupon_registration" })}>
      <CardLayout header={getHeader()} sx={{ my: 2 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="end"
          spacing={1}
          rowSpacing={2}
        >
          <Grid item xs={9}>
            <TextField
              fullWidth
              name="name"
              value={couponNumber}
              onChange={handleChange}
              placeholder={intl.formatMessage({
                id: "point.enter_coupon_number",
              })}
              error={isError}
              helperText={
                isError && (
                  <span>
                    {intl.formatMessage({ id: "point.invalid_coupon_number" })}
                  </span>
                )
              }
              InputProps={{
                inputProps: { maxLength: 16 },
                sx: {
                  height: "48px",
                },
              }}
            />
          </Grid>
          <Grid item alignSelf="start" xs={3}>
            <CustomButton size="large" onClick={register}>
              {intl.formatMessage({ id: "point.application" })}
            </CustomButton>
          </Grid>
        </Grid>
      </CardLayout>

      <Modal visible={modalVisible} onClose={() => setModalVisible(false)}>
        {intl.formatMessage({ id: "point.coupon_registered" })}
      </Modal>
      {/* {notices.length > 0 && (
        <Stack sx={{ px: 1 }}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            유의사항
          </Typography>
          {notices.map((notice) => (
            <ListItem key={notice} sx={{ px: 0, py: 0.5 }}>
              <Stack direction="row" alignItems="flex-start">
                <span style={{ marginInlineEnd: "8px" }}>•</span>
                notice
              </Stack>
            </ListItem>
          ))}
        </Stack>
      )} */}
    </Layout>
  );
};

export default RegisterPoint;
