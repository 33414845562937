import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TrainService from "services/trainService";
import UserService, { HanpassCouponInterface } from "services/userService";
import { ProviderUtils } from "utils/providerUtils";

const getSortedValidHanpassCoupons = (array: HanpassCouponInterface[]) => {
  const sortedHanpassCoupons = [...array];
  sortedHanpassCoupons.sort((a, b) => {
    const dateA = new Date(a.expiredDate);
    const dateB = new Date(b.expiredDate);

    return dateA.getTime() - dateB.getTime();
  });
  return sortedHanpassCoupons.filter((coupon) => !coupon.deleted);
};

const useHanpassCoupon = () => {
  const { orderId } = useParams();

  const [hanpassCoupons, setHanpassCoupons] = useState<
    HanpassCouponInterface[]
  >([]);
  const [selectedCoupon, setSelectedCoupon] = useState<
    HanpassCouponInterface | undefined | null
  >();
  const [hanpassCouponDiscountAmount, setHanpassCouponDiscountAmount] =
    useState<number>(0);

  useEffect(() => {
    const getHanpassCoupons = async () => {
      const data = await UserService.getHanpassCoupons();
      const validHanpassCoupons = getSortedValidHanpassCoupons(data);
      const currentCoupon = validHanpassCoupons[0];

      setHanpassCoupons(validHanpassCoupons);
      setSelectedCoupon(currentCoupon);
    };

    if (ProviderUtils.isHanpass) {
      getHanpassCoupons();
    }
  }, []);

  useEffect(() => {
    const getHanpassCouponDiscountAmount = async () => {
      if (!selectedCoupon) {
        setHanpassCouponDiscountAmount(0);
        return;
      }
      const currentDiscountAmount =
        await TrainService.getHanpassCouponDiscountAmount(
          Number(orderId),
          selectedCoupon!.memberCouponSeq
        );
      setHanpassCouponDiscountAmount(currentDiscountAmount);
    };
    if (ProviderUtils.isHanpass) {
      getHanpassCouponDiscountAmount();
    }
  }, [orderId, selectedCoupon]);

  return {
    hanpassCoupons,
    selectedCoupon,
    hanpassCouponDiscountAmount,
    setSelectedCoupon,
  };
};

export default useHanpassCoupon;
