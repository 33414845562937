export const enum SubdomainProvider {
  Hanpass = "hpnew",
  Paybooc = "paybooc",
  Lottecard = "lottecard",
  Travelwallet = "travelwallet",
  GME = "gme",
  KTmobile = "ktm",
  Mame = "mame",
}

export const enum TestProvider {
  Hanpass = "test",
  // Paybooc = "test",
  Lottecard = "lottetest",
  Travelwallet = "test",
  GME = "gmetest",
  KTmobile = "test",
  Mame = "test",
  Dev = "test",
  Local = "localhost",
  Staging = "lachastaging",
}

export class ProviderUtils {
  static testProvidersWithDiscount = [
    TestProvider.Dev,
    TestProvider.Local,
    TestProvider.GME,
    TestProvider.Lottecard,
    TestProvider.Staging,
  ];

  static testProvidersWithCommission = [];

  // KTX 할인 적용되는 고객사
  static providersWithDiscount = [
    SubdomainProvider.Hanpass,
    SubdomainProvider.Paybooc,
    SubdomainProvider.Lottecard,
    SubdomainProvider.Travelwallet,
    SubdomainProvider.KTmobile,
    SubdomainProvider.GME,
    ...this.testProvidersWithDiscount,
  ] as const;

  // 수수료 부과되는 고객사
  static providersWithCommission = [
    SubdomainProvider.Mame,
    ...this.testProvidersWithCommission,
  ];

  // 전체 고객사 리스트
  static providerList = [
    ...this.providersWithDiscount,
    ...this.providersWithCommission,
  ] as const;

  // lacha 적립금을 보여주는 고객사
  static providerWithPoint = [
    SubdomainProvider.KTmobile,
    SubdomainProvider.Lottecard,
    TestProvider.Lottecard,
    TestProvider.Local,
  ];

  // dark mode 가능한 고객사
  static darkModeProvider = [
    SubdomainProvider.KTmobile,
    SubdomainProvider.Mame,
    // TestProvider.Local,
  ];

  // provider
  private static getProvider(): SubdomainType | undefined {
    const subdomain = window.location.hostname.split(".")[0];

    const isSubdomain = (provider: string): provider is SubdomainType => {
      return this.providerList.includes(provider as SubdomainType);
    };

    if (isSubdomain(subdomain)) {
      return subdomain;
    }
    return undefined;
  }

  static readonly provider = this.getProvider();

  //
  static isDiscountProvider =
    !this.provider || // lacha.co.kr
    this.providersWithDiscount.includes(this.provider as DiscountSubdomainType);

  static isPointProvider =
    !this.provider ||
    this.providerWithPoint.includes(this.provider as SubdomainType);

  static isDarkModeProvider =
    !this.provider ||
    this.darkModeProvider.includes(this.provider as SubdomainProvider);

  static isLogoutUnavailableProvider = [
    SubdomainProvider.Hanpass,
    SubdomainProvider.GME,
    TestProvider.GME,
    SubdomainProvider.Lottecard,
    TestProvider.Lottecard,
  ].includes(this.provider as SubdomainType);

  static isPaybooc = [SubdomainProvider.Paybooc, 
    // TestProvider.Paybooc
  ].includes(
    this.provider as SubdomainProvider
  );

  static isHanpass = [SubdomainProvider.Hanpass, TestProvider.Hanpass].includes(
    this.provider as SubdomainProvider
  );

  static isGME = [SubdomainProvider.GME, TestProvider.GME].includes(
    this.provider as SubdomainType
  );

  static isLottecard = [
    SubdomainProvider.Lottecard,
    TestProvider.Lottecard,
  ].includes(this.provider as SubdomainType);

  static isMame = this.provider === SubdomainProvider.Mame;
}

type DiscountSubdomainType =
  (typeof ProviderUtils.providersWithDiscount)[number];
export type SubdomainType = (typeof ProviderUtils.providerList)[number];
