import { Stack, Typography, useTheme } from "@mui/material";
import useLanguages from "hooks/useLanguages";

const RecentLoginIcon = () => {
  const theme = useTheme();
  const { isKorean } = useLanguages();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        maxWidth: 48,
        wordBreak: "keep-all",
        position: "absolute",
        right: 4,
        top: "50%",
        transform: "translateY(-50%)",
        bgcolor: theme.palette.white.main,
        borderRadius: 0.75,

        textAlign: "center",

        px: 1,
        py: 0.5,

        "&:after": {
          content: "''",
          position: "absolute",
          left: "-12px" /* Adjusts the position of the arrow */,
          borderWidth: "4px",
          borderRightWidth: "12px",
          borderStyle: "solid",
          borderColor: `transparent ${theme.palette.white.main} transparent transparent`,
        },
      }}
    >
      <Typography
        variant="caption"
        color={theme.palette.black.main}
        sx={{
          lineHeight: 1.2,
          fontWeight: "bold",
          fontSize: "0.6rem",
        }}
      >
        {isKorean ? "최근 로그인" : "Recently used"}
      </Typography>
    </Stack>
  );
};

export default RecentLoginIcon;
