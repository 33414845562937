import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Portal from "./Portal";
import { Card, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface ModalProps {
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  maskClosable: boolean;
  closable: boolean;
  visible: boolean;
  children: React.ReactNode;
}

function Modal({
  onClose,
  maskClosable = true,
  closable = true,
  visible,
  children,
}: ModalProps) {
  const onMaskClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  const close = (e: React.MouseEvent<HTMLElement>) => {
    if (onClose) {
      onClose(e);
    }
  };

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed;
      top: -${window.scrollY}px;
      overflow-y: hidden;
      width: 100%;`;
    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <Portal elementId="modal-root">
      <ModalWrapper
        onClick={maskClosable ? onMaskClick : undefined}
        tabIndex={-1}
        visible={visible}
      >
        <ModalInner tabIndex={0}>
          {closable && (
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          )}
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              "& .MuiPaper-root": {
                boxShadow: "none",
                p: 1,
              },
            }}
          >
            {children}
          </Stack>
        </ModalInner>
      </ModalWrapper>
    </Portal>
  );
}

Modal.defaultProps = {
  visible: false,
  closable: true,
  maskClosable: true,
};

Modal.propTypes = {
  visible: PropTypes.bool,
};

const ModalWrapper = styled(Card)<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  background-color: #0009;
  border-radius: 0;
`;

const ModalInner = styled(Card)`
  position: relative;
  min-width: 320px;
  max-width: 360px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 8px 16px 16px;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export default Modal;
