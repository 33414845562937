export const calculateCurrentDate = (): string => {
  const date = new Date();
  // return date.toISOString().split("T")[0];
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate()}`;
};

export const calculateDateBeforeOneMonth = (): string => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  return date.toISOString().split("T")[0];
};

export const calculateDateBeforeLong = (): string => {
  const date = new Date();
  date.setFullYear(1900);
  return date.toISOString().split("T")[0];
};
