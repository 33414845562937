import { Typography, Grid, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import CustomButton from "components/button/CustomButton";
import useGetOrderName from "hooks/useGetOrderName";
import { useNavigate } from "react-router-dom";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import { getSeatDetailsFromOrder } from "utils/formatUtils";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";
import Arrow from "components/Arrow";
import StationInfo from "components/booking_info/StationInfo";
import { useGetTicketDetails } from "hooks/bookingInfos/hooks";

interface ProductInfoProps {
  order: OrderType;
}

const ProductInfoSection = ({ order }: ProductInfoProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();
  const getOrderName = useGetOrderName();
  const navigate = useNavigate();
  const formatDateTime = useFormatDateToLocaleString();
  const formatSeatDetails = useFormatSeatDetails();

  const seatDetails = getSeatDetailsFromOrder(order);
  const ticketDetails = useGetTicketDetails(order);
  const stationDetail = {
    departure: ticketDetails.departure,
    arrival: ticketDetails.arrival,
    startDate: ticketDetails.departureTime,
    endDate: ticketDetails.arrivalTime,
  };

  const showBoardingPass = () => {
    navigate(`/order-details/${order.orderId}/boarding-pass`, { state: order });
  };

  const getHeader = () => {
    return (
      <Typography variant="h6" sx={{ width: "100%" }}>
        {getOrderName(order.orderName)}
      </Typography>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 1.5, width: "100%" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.price" />:
          </Typography>

          <Typography
            variant="h6"
            component="div"
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            {formatPrice(Number(order.totalPrice) - order.goodsList.price)}
          </Typography>
        </Stack>
        {order.orderStatus === "COMPLETE" && (
          <CustomButton onClick={showBoardingPass}>
            <FormattedMessage id="orders.showTrainTicket" />
          </CustomButton>
        )}
      </>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <Stack gap={1.5}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={3}
          sx={{ width: "100%", mt: 1 }}
        >
          <StationInfo status="DEPARTURE" detail={stationDetail} />
          <Arrow />
          <StationInfo status="ARRIVAL" detail={stationDetail} />
        </Stack>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "center" }}>
          {ticketDetails.duration}
        </Typography>
        <Grid container spacing={1}>
          <DetailGridItem
            id="orders.reserveNumber"
            text={order.apiReserveNumber}
          />
          <DetailGridItem
            id="trainMain.dateOfDeparture"
            text={formatDateTime(order.startDate, {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          />
          <DetailGridItem
            id="orders.trainNumber"
            text={`${order.trainList[0].trainType} ${parseInt(
              order.trainList[0].trainCode
            )}`}
          />
          <DetailGridItem
            id="booking.passengers"
            text={intl.formatMessage(
              { id: "booking.passengersDetail" },
              {
                total: order.adultCount + order.childrenCount,
                adults: order.adultCount,
                children: order.childrenCount,
              }
            )}
          />
          <DetailGridItem
            id="booking.seats"
            text={formatSeatDetails(seatDetails)}
          />
        </Grid>
      </Stack>
    </CardLayout>
  );
};

export default ProductInfoSection;

const DetailGridItem = ({ id, text }: { id: string; text?: string }) => {
  return (
    <Grid item display="flex" xs={12} justifyContent="space-between">
      <Typography variant="body1" color="text.secondary">
        <FormattedMessage id={id} />:
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {text}
      </Typography>
    </Grid>
  );
};
