import { useMemo } from "react";
import { createPortal } from "react-dom";

function Portal({
  children,
  elementId,
}: {
  children: React.ReactNode;
  elementId: string;
}) {
  const rootElement: HTMLElement = useMemo(
    () => document.getElementById(elementId)!,
    [elementId]
  );

  return createPortal(children, rootElement);
}

export default Portal;
