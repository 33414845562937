import Layout from "components/layout/Layout";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import BookingInfos from "../../components/booking_info/BookingInfos";
import QRCode from "components/QRCode";
import { Container, Typography } from "@mui/material";
import TickerNotice from "./components/TickerNotice";
import { Stack } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import useLanguages from "hooks/useLanguages";

const BoardingPass = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Layout
      text={intl.formatMessage({ id: "orders.showTrainTicket" })}
      onBack={() => navigate(-1)}
    >
      {state.orderStatus === "COMPLETE" ? (
        <Container
          // maxWidth="false"
          sx={{ mt: 3, mb: 2, px: 0, wordBreak: "keep-all" }}
        >
          <BookingInfos
            order={state}
            qrCode={
              <QRCode
                src={`data:image/png;base64,${state?.trainList[0].qrCodeImage}`}
              />
            }
          />
          <TickerNotice />
        </Container>
      ) : (
        <Stack
          justifyContent="space-around"
          alignItems="center"
          sx={{ width: "100%", minHeight: "50dvh" }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{ width: "100%", my: 6, textAlign: "center" }}
          >
            <CancelIcon color="error" sx={{ fontSize: 120 }} />
            <Typography
              variant="h6"
              sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
            >
              {/* <FormattedMessage id="payment.cancel" /> */}
              {isKorean ? "취소된 승차권입니다." : "Cancelled train ticket."}
            </Typography>
          </Stack>
          {/* <Stack
            alignItems="center"
            gap={1}
            sx={{ maxWidth: "320px", width: "100%" }}
          >
            <CustomButton
              color="error"
              variant="outlined"
              onClick={closeWindow}
              style={{
                // backgroundColor: theme.palette.white.main,
                textTransform: "capitalize",
              }}
            >
              닫기
            </CustomButton>
          </Stack> */}
        </Stack>
      )}
    </Layout>
  );
};

export default BoardingPass;
