import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIntl, FormattedMessage } from "react-intl";
import parse from "html-react-parser";

const CancelTermSection = () => {
  const intl = useIntl();

  return (
    <Stack sx={{ width: "100%" }}>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          "& .MuiButtonBase-root": { px: 0 },
          "& .MuiAccordionSummary-content": { my: 0 },
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <FormattedMessage id="term1.title" />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            fontSize: "0.1rem",
          }}
        >
          <Divider />
          {parse(`${intl.formatMessage({ id: "term1.content" })}`)}
        </AccordionDetails>
      </Accordion>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          "& .MuiButtonBase-root": { px: 0 },
          "& .MuiAccordionSummary-content": { my: 0 },
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <FormattedMessage id="term4.title" />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            fontSize: "0.1rem",
            overflowX: "scroll",
          }}
        >
          <Divider />
          {parse(`${intl.formatMessage({ id: "term4.content" })}`)}
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default CancelTermSection;
