import defaultLogo from "assets/images/logo/logo.png";
import payboocLogo from "assets/images/logo/paybooc_logo.png";
import gmeLogo from "assets/images/logo/gme_logo.png";
import lottecardLogo from "assets/images/logo/lottecard_logo.png";
import { useNavigate } from "react-router-dom";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import ThemeToggle from "components/ThemeToggle";
import { styled } from "@mui/material/styles";
import { ProviderUtils } from "utils/providerUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Stack } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";

const getLogo = () => {
  if (ProviderUtils.isPaybooc) {
    return payboocLogo;
  }

  if (ProviderUtils.isLottecard) {
    return lottecardLogo;
  }

  if (ProviderUtils.isGME) {
    return gmeLogo;
  }

  return defaultLogo;
};

const Header = () => {
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate("/user-settings");
  };

  return (
    <Wrapper
      style={{
        height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,

        padding: "0 16px",
        paddingTop: `env(safe-area-inset-top)`,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        {/* Lotte Card에서만 보여주기 */}
        {ProviderUtils.isLottecard && (
          <IconButton
            onClick={closeWebview}
            size="small"
            edge="end"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Logo
          src={getLogo()}
          // src={gmeLogo}
          alt="LACHA Logo"
          sx={
            ProviderUtils.isGME
              ? { width: 73, height: 20 }
              : { height: ProviderUtils.isLottecard ? 16 : 24, width: "auto" }
          }
        />
      </Stack>
      <Stack
        direction="row"
        gap={1}
        sx={{
          "& > * ": {
            cursor: "pointer",
          },
        }}
      >
        {ProviderUtils.isDarkModeProvider && <ThemeToggle />}
        <LanguageSelectIcon />
        <Menu aria-label="menu" onClick={handleMenuClick} />
      </Stack>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled("header")(
  ({ theme }) => `
    direction: ltr;
    background-color:${theme.palette.white.main};

    display: flex;
    justify-content: space-between;
    align-items: center;

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;

    position: fixed;
    top: 0px;
    z-index: 100000000;
    }
`
);

const Logo = styled("img")`
  height: 55%;
  /* gme Logo는 20px */
  /* height: 20px; */
`;
