import { Card, Grid, Typography } from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";

const Banner = () => {
  return (
    <Card
      sx={{
        m: 2,
        p: 2,
        px: 3,
        // bgcolor: "primary.light",
        bgcolor: "homeBanner.bgColor",
        border: "none",
        position: "relative",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          minHeight: "80px",
          color: "black.main",
        }}
      >
        <Grid item xs={8}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.2,
            }}
          >
            라차에서 예약하면
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.4,
              wordBreak: "keep-all",
            }}
          >
            KTX 최대 20% 할인
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <StyleIcon
            sx={{
              fontSize: `150px`,
              position: "absolute",
              top: -20,
              right: -40,
              transform: "scaleY(-1)",
              // color: "grey.700",
              color: "homeBanner.textColor",
              opacity: "0.7",
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Banner;
