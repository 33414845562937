import { Stack } from "@mui/system";
import Modal from "./Modal";
import { Typography } from "@mui/material";

interface PaymentModalProps {
  modalVisible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
export const PaymentModal = ({
  modalVisible,
  onClose,
  children,
}: PaymentModalProps) => {
  return (
    <Modal visible={modalVisible} onClose={onClose}>
      <Stack gap={2} sx={{ p: 1 }}>
        <div>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            결제 오류 발생 시, https://lacha.co.kr 에서 결제하실
            수 있습니다.
          </Typography>
        </div>
        {children}
      </Stack>
    </Modal>
  );
};
