// export const PaymentMethod = {
//   CREDIT: "Credit",
//   HANPASS: "Hanpass",
// };

export enum PaymentMethod {
  CREDIT = "Credit",
  HANPASS = "Hanpass",
  GME = "GME",
  OVERSEAS = "Overseas",
}

export type PaymentMethodValue = `${PaymentMethod}`;
