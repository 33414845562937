import { Stack, Typography } from "@mui/material";
import Arrow from "components/Arrow";
import StationInfo from "components/booking_info/StationInfo";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import TrainLogo from "components/TrainLogo";
import React from "react";
import { useIntl } from "react-intl";

interface StationDetails {
  departure: string;
  arrival: string;
  startDate: string;
  endDate: string;
}

interface TravelDetails {
  trainNumber: string;
  duration: React.ReactElement;
}

interface TicketContentProps {
  stationDetails: StationDetails;
  travelDetails: TravelDetails;
  ticketHeader: React.ReactNode;
  ticketAdditionalInfo: React.ReactNode;
}

const TicketContent = ({
  stationDetails,
  travelDetails,
  ticketHeader,
  ticketAdditionalInfo,
}: TicketContentProps) => {
  const intl = useIntl();

  return (
    <>
      <CustomButton
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        {intl.formatMessage({ id: "orders.trainTicket" })}
      </CustomButton>
      <CardLayout
        header={ticketHeader}
        additionalInfo={ticketAdditionalInfo}
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
          sx={{ width: "100%" }}
        >
          <TrainLogo size="large" />
          <Typography
            variant="subtitle2"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            {travelDetails.trainNumber}
          </Typography>
        </Stack>

        {/* Departure and Arrival Stations */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{ width: "100%" }}
        >
          <StationInfo status="DEPARTURE" detail={stationDetails} />
          <Arrow />
          <StationInfo status="ARRIVAL" detail={stationDetails} />
        </Stack>

        <Typography variant="body1" sx={{ mb: 1, textAlign: "center" }}>
          {travelDetails.duration}
        </Typography>
      </CardLayout>
    </>
  );
};

export default TicketContent;
