import { PaymentMethodValue } from "const/paymentMethod";
import history from "history/browser";
import { useState } from "react";
import PaymentService from "services/paymentService";
import useDebounce from "./useDebounce";

const useProcessPayment = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const debounce = useDebounce();

  const processPayment = async (
    orderId: number,
    paymentMethod: PaymentMethodValue,
    point: number = 0,
    externalCouponId?: number
  ) => {
    const payment = async () => {
      setIsProcessing(true);

      try {
        switch (paymentMethod) {
          case "GME":
            const { availablePay } = await PaymentService.paymentRequest(
              orderId,
              paymentMethod,
              point,
              externalCouponId
            );

            return availablePay;

          default:
            await PaymentService.initializePayment(
              orderId,
              paymentMethod,
              point,
              externalCouponId
            );
            history.replace("/order-history");
        }
      } catch (error) {
        if (error instanceof Error) {
          alert(`결제 실패:  ${error.message}`);
        }
      } finally {
        setIsProcessing(false);
      }
    };

    debounce(payment);
  };

  return [isProcessing, processPayment] as const;
};

export default useProcessPayment;
