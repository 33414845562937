import { Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PaymentMethodValue } from "const/paymentMethod";
import CardLayout from "components/layout/CardLayout";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

interface PaymentMethodSectionProps {
  paymentMethod: PaymentMethodValue;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodValue>>;
}

const PaymentMethodSection = ({
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodSectionProps) => {
  const { isKorean, isJapanese } = useLanguages();

  const handlePaymentMethodChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newPaymentMethod: PaymentMethodValue
  ) => {
    if (newPaymentMethod !== null) {
      setPaymentMethod(newPaymentMethod);
    }
  };

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        <FormattedMessage id="payment.paymentMethodSelect" />
      </Typography>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <ToggleButtonGroup
        color="secondary"
        value={paymentMethod}
        exclusive
        onChange={handlePaymentMethodChange}
        size="medium"
        sx={{ display: "flex", justifyContent: "center", mt: 1 }}
      >
        {ProviderUtils.isHanpass && (
          <ToggleButton value="Hanpass" aria-label="hanpass wallet">
            <FormattedMessage id="payment.hanpassWallet" />
          </ToggleButton>
        )}
        {ProviderUtils.isGME && (
          <ToggleButton value="GME" aria-label="GME pay">
            {isKorean ? "GME 페이" : "GME PAY"}
          </ToggleButton>
        )}
        {!ProviderUtils.isMame && (
          <ToggleButton value="Credit" aria-label="credit card">
            <FormattedMessage id="payment.creditCard" />
          </ToggleButton>
        )}
        {ProviderUtils.isMame && (
          <ToggleButton value="Overseas" aria-label="Overseas card">
            {isJapanese
              ? "海外カード"
              : isKorean
              ? "해외카드"
              : "Overseas card"}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </CardLayout>
  );
};

export default PaymentMethodSection;
