import { Card, CardContent, Typography } from "@mui/material";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Stack } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import { FindObjective } from "services/authService";

interface LocationState {
  emails: string[];
  email: string;
  objective: FindObjective;
  isModal: boolean;
}

// Function to format the user ID
const formatEmail = (email: string): string => {
  const [prefix, domain] = email.split("@");
  const STAR_NUMBER = 3;
  return `${prefix.slice(0, -STAR_NUMBER)}${"*".repeat(STAR_NUMBER)}@${domain}`;
};

const ConfirmationPage = () => {
  const { emails, email, objective, isModal } = useLocation()
    .state as LocationState;
  const navigate = useNavigate();
  const intl = useIntl();

  const handleLoginClick = () => {
    navigate("/login", { state: { backToSearchResult: isModal } });
  };

  const handleFindPassword = () => {
    navigate("/find-password", { state: { isModal } });
  };

  return (
    <Layout
      text={
        objective === "findId"
          ? intl.formatMessage({ id: "user.findId" })
          : intl.formatMessage({ id: "user.forgot" })
      }
      onBack={() => navigate(-2)}
    >
      <Card sx={{ m: 4, mx: 2, textAlign: "center" }}>
        <CardContent>
          <Stack gap={3}>
            <Stack alignItems="center">
              {objective === "findId" ? (
                <>
                  <Typography gutterBottom variant="h5" component="div">
                    <FormattedMessage id="forgot.findIdSuccess1" />
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    <FormattedMessage id="forgot.findIdSuccess2" />
                    {emails.map((email: string) => (
                      <Typography
                        key={email}
                        variant="h5"
                        color="primary"
                        sx={{ my: 1 }}
                      >
                        {` ${formatEmail(email)} `}
                      </Typography>
                    ))}
                    <FormattedMessage id="forgot.findIdSuccess3" />
                  </Typography>
                  <StorefrontIcon sx={{ fontSize: 100, my: 2 }} />
                  <Typography variant="body2" color="text.secondary">
                    <FormattedMessage id="forgot.findIdSuccess4" />
                  </Typography>
                </>
              ) : (
                <>
                  <MailOutlineIcon sx={{ fontSize: 100, my: 2 }} />
                  <Typography
                    component="div"
                    variant="body1"
                    color="text.secondary"
                  >
                    <FormattedMessage
                      id="forgot.resetPassword"
                      values={{
                        email: (
                          <Typography component="span" color="text.secondary">
                            <Typography variant="h6" color="primary">
                              {email}
                            </Typography>
                          </Typography>
                        ),
                      }}
                    />
                  </Typography>
                </>
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <CustomButton
                id="user.signIn"
                size="medium"
                onClick={handleLoginClick}
              />
              {objective === "findId" && (
                <CustomButton
                  id="user.forgot"
                  variant="outlined"
                  size="medium"
                  onClick={handleFindPassword}
                />
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default ConfirmationPage;
