import React, { useEffect, useRef, useReducer, useCallback } from "react";
import {
  Container,
  Card,
  Tabs,
  Tab,
  TextField,
  Typography,
  Grid,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService, { type FindObjective } from "services/authService";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/system";
import useValidate from "hooks/useValidate";
import useInputs from "hooks/useInputs";
import { FormattedMessage, useIntl } from "react-intl";

// Format the remaining time in minutes and seconds
const formatSec = (sec: number): string => {
  const minutes = Math.floor(sec / 60);
  const seconds = sec % 60;
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const initialForm = {
  name: "",
  phone: "",
  email: "",
  verificationCode: "",
};

type MethodType = "phone" | "email";

interface FindState {
  method: MethodType;
  error: string;
  timer: number;
}

const initialState: FindState = {
  method: "phone",
  error: "",
  timer: 0,
};

const VERIFICATION_TIMER = 180; // 3 minutes in seconds

type ActionType =
  // | { type: "RESET_VERIFICATION_CODE" }
  | { type: "CHANGE_METHOD"; value: FindState["method"] }
  | { type: "SET_ERROR"; value: FindState["error"] }
  | { type: "RESET_ERROR" }
  | { type: "SET_TIMER"; value: FindState["timer"] }
  | { type: "RESET_TIMER" };

const reducer = (state: FindState, action: ActionType) => {
  switch (action.type) {
    // case "RESET_VERIFICATION_CODE":
    //   return {
    //     ...state,
    //     inputs: {
    //       ...state.inputs,
    //       verificationCode: "",
    //     },
    //   };
    case "CHANGE_METHOD":
      return {
        ...state,
        method: action.value,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.value,
      };
    case "RESET_ERROR":
      return {
        ...state,
        error: "",
      };
    case "SET_TIMER":
      return {
        ...state,
        timer: action.value,
      };
    case "RESET_TIMER":
      return {
        ...state,
        timer: 0,
      };
    default:
      const _exhaustiveCheck: never = action;
      return _exhaustiveCheck;
  }
};

const initialValidState = {
  phone: true,
  email: true,
};

const FindPwPage = () => {
  const [validState, validate] = useValidate(initialValidState);
  const { form, onChange, reset } = useInputs(initialForm);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { method, error, timer } = state;
  const theme = useTheme();

  const intl = useIntl();
  const navigate = useNavigate();
  const { isModal } = useLocation().state as { isModal: boolean };

  const objective: FindObjective = "resetPw";
  const verificationResponse = useRef("");
  const verificationInput = useRef<HTMLInputElement>(null);

  // Set verification code timer
  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;
    if (timer > 0) {
      interval = setInterval(() => {
        dispatch({ type: "SET_TIMER", value: timer - 1 });
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name !== "name" && name !== "verificationCode") {
      validate(e);
    }
    onChange(e);
  };

  // 인증번호 발행을 위한 필드 검사 함수
  const validateVerificationCodeForm = useCallback(() => {
    // TODO: valid한 경우에만 발행 가능하도록 수정
    if (!form.name) {
      alert(intl.formatMessage({ id: "forgot.nameMissing" }));
      return false;
    }
    if (method === "phone" && !form.phone) {
      alert(intl.formatMessage({ id: "forgot.phoneMissing" }));
      return false;
    }
    if (method === "email" && !form.email) {
      alert(intl.formatMessage({ id: "forgot.emailMissing" }));
      return false;
    }
    return true;
  }, [form.email, form.name, form.phone, intl, method]);

  // 폼 데이터 유효성 검증
  const validateForm = useCallback(() => {
    if (!validateVerificationCodeForm()) {
      return false;
    }
    if (!form.verificationCode) {
      alert(intl.formatMessage({ id: "forgot.codeMissing" }));
      return false;
    }
    return true;
  }, [form.verificationCode, intl, validateVerificationCodeForm]);

  // 인증번호 발송 핸들러
  const throttleTimer = useRef<ReturnType<typeof setTimeout> | null>();
  const requestVerificationCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    verificationInput.current!.focus();

    if (!validateVerificationCodeForm()) {
      return;
    }

    reset("verificationCode");
    dispatch({ type: "SET_TIMER", value: VERIFICATION_TIMER });

    if (!throttleTimer.current) {
      throttleTimer.current = setTimeout(async () => {
        try {
          throttleTimer.current = null;

          const userId = await AuthService.startVerification(
            form.name,
            method === "phone" ? form.phone : undefined,
            method === "email" ? form.email : undefined,
            objective
          );
          // console.log("Verification started:", response);
          verificationResponse.current = userId;
        } catch (error) {
          console.error("Error starting the verification process:", error);
          // setError("Verification start failed. Please try again.");
          dispatch({
            type: "SET_ERROR",
            value: intl.formatMessage({ id: "forgot.codeSendFailed" }),
          });
          dispatch({ type: "RESET_TIMER" });
        }
      }, 3000);
    }
  };

  const handleTryVerify = async () => {
    if (!verificationResponse.current) {
      alert(intl.formatMessage({ id: "forgot.codeNotSent" }));
      return;
    }

    try {
      const { email } = await AuthService.tryVerify({
        userId: `${verificationResponse.current}`,
        code: form.verificationCode,
      });
      console.log("Verification response:", email);

      if (email) {
        navigate("/confirmation", {
          state: { email, objective, isModal },
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error during the tryVerify process:", error);
        alert("인증 실패: " + error.message);
      }
    }
  };

  // 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: "RESET_ERROR" });

    if (!validateForm()) {
      return;
    }
    await handleTryVerify(); // 인증 시도
  };

  // 토글 버튼 변경 핸들러
  const handleMethodChange = useCallback(
    (_e: React.SyntheticEvent, newValue: MethodType) => {
      dispatch({
        type: "CHANGE_METHOD",
        value: newValue,
      });
      reset();
      dispatch({ type: "RESET_TIMER" });
      dispatch({ type: "RESET_ERROR" });
    },
    [reset]
  );

  return (
    <Layout text={intl.formatMessage({ id: "user.forgot" })}>
      <Container maxWidth="sm" sx={{ marginTop: 0, p: 0 }}>
        <Tabs
          value={method}
          onChange={handleMethodChange}
          variant="fullWidth"
          sx={{
            height: "54px",
            borderBottom: `1px solid ${alpha(theme.border.color.main, 0.3)}`,
          }}
        >
          <Tab
            label={intl.formatMessage({ id: "forgot.withPhone" })}
            value="phone"
            sx={{ height: "54px", fontWeight: "bold" }}
          />
          <Tab
            label={intl.formatMessage({ id: "forgot.withEmail" })}
            value="email"
            sx={{ height: "54px", fontWeight: "bold" }}
          />
        </Tabs>

        <Card sx={{ p: 2, my: 2 }}>
          <Stack alignItems="center">
            {error && (
              <Typography variant="body2" color="error" alignSelf="end">
                {error}
              </Typography>
            )}

            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Stack spacing={2} sx={{ mb: 3 }}>
                <Stack gap={0.7}>
                  <Typography variant="h6">
                    <FormattedMessage id="signup.name" />{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    label={intl.formatMessage({ id: "forgot.enterName" })}
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    size="small"
                  />
                </Stack>
                <Stack gap={0.7}>
                  {method === "phone" ? (
                    <>
                      <Typography variant="h6">
                        <FormattedMessage id="signup.phone" />{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        type="tel"
                        name="phone"
                        label={intl.formatMessage({ id: "forgot.enterPhone" })}
                        value={form.phone}
                        onChange={handleChange}
                        required
                        size="small"
                        error={!!form.phone && !validState.phone}
                        helperText={
                          form.phone &&
                          !validState.phone && (
                            <span>
                              <FormattedMessage id="forgot.phoneInvalid" />
                            </span>
                          )
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="h6">
                        <FormattedMessage id="signup.email" />{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextField
                        fullWidth
                        type="email"
                        name="email"
                        label={intl.formatMessage({ id: "forgot.enterEmail" })}
                        value={form.email}
                        onChange={handleChange}
                        required
                        size="small"
                        error={!!form.email && !validState.email}
                        helperText={
                          form.email &&
                          !validState.email && (
                            <span>
                              <FormattedMessage id="forgot.emailInvalid" />
                            </span>
                          )
                        }
                      />
                    </>
                  )}
                </Stack>
                <Stack gap={0.7}>
                  <div>
                    <Typography variant="h6">
                      <FormattedMessage id="forgot.code" />{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    {timer > 0 && (
                      <Typography variant="body2" color="primary">
                        <FormattedMessage id="forgot.timeLimit" />:{" "}
                        {formatSec(timer)}
                      </Typography>
                    )}
                  </div>
                  <Grid container columnSpacing={1} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        inputRef={verificationInput}
                        label={intl.formatMessage({ id: "forgot.enterCode" })}
                        name="verificationCode"
                        value={form.verificationCode}
                        onChange={handleChange}
                        required
                        fullWidth
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <CustomButton
                        type="button"
                        size="medium"
                        onClick={requestVerificationCode}
                      >
                        {timer > 0
                          ? intl.formatMessage({ id: "forgot.retryCode" })
                          : intl.formatMessage({ id: "forgot.sendCode" })}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>

              <CustomButton
                disabled={
                  timer === 0 ||
                  !(verificationResponse.current && form.verificationCode)
                }
              >
                <FormattedMessage id="forgot.verify" />
              </CustomButton>
            </form>
          </Stack>
        </Card>
      </Container>
    </Layout>
  );
};

export default FindPwPage;
