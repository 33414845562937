interface ConsentState {
  [K: string]: boolean;
}

export const makeAllOptionsTrue = <T extends ConsentState>(obj: T): T => {
  const newState = Object.fromEntries(
    Object.entries(obj).map(([key]) => [key, true])
  ) as T;
  return newState;
};
