import { useNavigate } from "react-router-dom";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { FormattedMessage } from "react-intl";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";
import { useAppSelector } from "app/hooks";

const PersonalInfoSettingSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery("(min-width:321px)");
  const { name } = useAppSelector((state) => state.user);

  const handleEditClick = () => {
    navigate("/personal-info");
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        bgcolor: theme.palette.white.main,
        height: "64px",
        position: "relative",
        overflowX: "hidden",
        width: `calc(100% + ${16 * (matches ? 2 : 1)}px)`,
        right: 8 * (matches ? 2 : 1),
        px: 2.5,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      }}
    >
      <Typography
        variant="subtitle1"
        component="div"
        sx={{ fontWeight: "bold" }}
      >
        <FormattedMessage id="user.welcome" values={{ name }} />
      </Typography>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        <LanguageSelectIcon />
        <SettingsIcon onClick={handleEditClick} />
      </Stack>
    </Stack>
  );
};

export default PersonalInfoSettingSection;
