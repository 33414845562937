import { useState } from "react";
import { Card, Divider } from "@mui/material";
import InformationEntryForm from "./InformationEntryForm";
import CompletionPage from "./CompletionPage";
import Layout from "components/layout/Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import CustomButton from "components/button/CustomButton";
import ConsentForm from "./sections/ConsentForm";
import useInputs from "hooks/useInputs";
import { makeSignUpFormData } from "utils/signupUtils";
import AuthService from "services/authService";
import { isAxiosError } from "axios";
import { SelectChangeEvent } from "@material-ui/core";
import useValidate from "hooks/useValidate";
import { Stack } from "@mui/system";
import { makeAllOptionsTrue } from "utils/consentUtils";
import { ProviderUtils, } from "utils/providerUtils";

type Communications = "email" | "kakao" | "sms";

// Add index signature to object
export const initialCommunicationMethods: { [key in Communications]: boolean } =
  {
    email: false,
    kakao: false,
    sms: false,
    // push: false,
    // mail: false,
  };

const initialAgreements = {
  termsOfService: false,
  privacyPolicy: false,
  etc: false,
  // promotions: false,
};

const initialValidState = {
  password: false,
  prefix: false,
  domain: false,
  typedDomain: false,
  phone: false,
};

const initialForm = {
  name: "",
  // id: "",
  password: "",
  confirmPassword: "",
  phone: "",
  prefix: "",
  domain: "domain.com",
  typedDomain: "",
};

export type CommunicationType = typeof initialCommunicationMethods;
export type AgreementType = typeof initialAgreements;
export type ValidStateType = typeof initialValidState;

export default function RegistrationTab() {
  const intl = useIntl();
  const {
    state: { isModal },
  } = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const { form, onChange, reset } = useInputs(initialForm);
  const [validState, validate] = useValidate(initialValidState);
  const [isIdAvailable, setIsIdAvailable] = useState<boolean | null>(null);

  // 사용자 동의를 관리하기 위한 상태
  const [agreements, setAgreements] = useState(initialAgreements);

  // 운영 정보 수신 방법 상태 관리
  const [communicationMethods, setCommunicationMethods] = useState(
    initialCommunicationMethods
  );

  // 동의 토글 변경을 처리하는 함수
  const handleAgreementChange = (name: keyof AgreementType) => {
    setAgreements((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleCommunicationChange = (method: keyof CommunicationType) => {
    setCommunicationMethods((prev) => ({
      ...prev,
      [method]: !prev[method],
    }));
  };

  const handleCommunicationAllAgree = () => {
    setCommunicationMethods(makeAllOptionsTrue(communicationMethods));
  };

  const handleAllAgree = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setAgreements(makeAllOptionsTrue(agreements));
    handleCommunicationAllAgree();
  };

  const handleNext = () => {
    setActiveStep((prev) => ++prev);
  };

  // 아이디 중복 확인 처리 함수
  const handleCheckUsername = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (
      !String(form.prefix).trim() ||
      form.domain === "domain.com" ||
      !form.domain ||
      (form.domain === "typing" && !form.typedDomain)
    ) {
      alert(intl.formatMessage({ id: "signup.enterEmail" }));
      return;
    }

    try {
      await AuthService.checkLoginIdAvailability(
        `${form.prefix}@${form.typedDomain || form.domain}`
      );
      setIsIdAvailable(true);
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.data === "DUPLICATE_LOGIN_ID"
      ) {
        setIsIdAvailable(false);
        // alert("이미 사용 중인 아이디입니다.");
      }
      console.error("Error checking id availability:", error);
      // alert("아이디 중복 검사 중 오류가 발생했습니다.");
    }
  };
  // 입력 필드 변경 처리 함수
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    // Require ID duplicate check when user changes ID
    if (name === "prefix") {
      setIsIdAvailable(null);
      validate(e);
    } else if (name === "typedDomain") {
      //   reset("typedDomain");
      setIsIdAvailable(null);
      validate(e);
    } else if (name !== "name" && name !== "confirmPassword") {
      validate(e);
    }

    onChange(e);
  };

  const handleSelect = (_e: SelectChangeEvent) => {
    const e = _e as React.ChangeEvent<HTMLInputElement>;
    reset("typedDomain");
    setIsIdAvailable(null);
    validate(e);
    onChange(e);
  };

  const isSubmitDisabled =
    !agreements.termsOfService || !agreements.privacyPolicy;

  // 폼 유효성 검사 함수
  const validateForm = () => {
    // 모든 필드가 채워졌는지 확인
    if (!form.name) {
      alert(intl.formatMessage({ id: "signup.enterName" }));
      return false;
    }
    // if (!form.id) {
    //   alert("아이디를 입력해 주세요.");
    //   return false;
    // }
    if (!form.password) {
      alert(intl.formatMessage({ id: "signup.enterPassword" }));
      return false;
    }
    if (!form.confirmPassword) {
      alert(intl.formatMessage({ id: "signup.enterPassword" }));
      return false;
    }
    if (!form.prefix) {
      if (!form.domain || (form.domain === "typing" && !form.typedDomain)) {
        alert(intl.formatMessage({ id: "signup.enterEmail" }));
        return false;
      }
    }
    if (!form.phone) {
      alert(intl.formatMessage({ id: "signup.enterPassword" }));
      return false;
    }

    // 아이디 중복체크 됐는지 확인
    if (!isIdAvailable) {
      alert(intl.formatMessage({ id: "signup.checkUsername" }));
      return false;
    }

    if (
      !validState.password ||
      !validState.prefix ||
      !(validState.domain || validState.typedDomain) ||
      !validState.phone
    ) {
      alert("유효한 값을 입력하세요");
      return false;
    }

    // 비밀번호가 일치하는지 확인
    if (form.password !== form.confirmPassword) {
      alert("비밀번호가 일치하지 않습니다.");
      return false;
    }

    return true;
  };

  // 폼 제출 처리 함수
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    // 회원가입에 필요한 데이터 구성
    const email = `${form.prefix}@${form.typedDomain || form.domain}`;
    const revFormData = makeSignUpFormData(communicationMethods);

    revFormData.append("name", String(form.name));
    revFormData.append("email", email);
    revFormData.append("phone", String(form.phone));

    revFormData.append("loginId", String(email));
    revFormData.append("pwHash", String(form.password));
    if (ProviderUtils.isMame) {
      revFormData.append("provider", "Mame");
      revFormData.append("language", "ja");
    }

    try {
      // AuthService를 이용한 회원가입 요청
      const response = await AuthService.register(revFormData);

      if (response.status === 201) {
        // 회원가입 성공 처리
        handleNext();
      }
    } catch (error) {
      console.error("회원가입 오류:", error);
      if (isAxiosError(error)) {
        alert(
          `회원가입 과정 중 오류가 발생했습니다. ${
            error.response!.data.errorMessage
          }`
        );
      }
    }
  };

  return (
    <Layout
      text={intl.formatMessage({ id: "signup.title" })}
      onBack={() => navigate(-1)}
    >
      {/* 현재 단계를 표시하는 스테퍼 컴포넌트 */}
      <Card sx={{ p: 3, my: 1 }}>
        {/* <Stepper activeStep={activeStep} /> */}
        {activeStep === 0 && (
          <Stack component="form" onSubmit={handleSubmit} gap={3}>
            <InformationEntryForm
              form={form}
              validState={validState}
              isIdAvailable={isIdAvailable}
              handleChange={handleChange}
              handleCheckUsername={handleCheckUsername}
              handleSelect={handleSelect}
            />
            <Divider />

            <Stack gap={0.5}>
              <CustomButton
                id="common.agreeAll"
                variant="outlined"
                onClick={handleAllAgree}
                style={{
                  margin: 0,
                  // backgroundColor: theme.palette.white,
                  backgroundColor: "white",
                }}
              />

              <ConsentForm
                agreements={agreements}
                onAgreementChange={handleAgreementChange}
                communicationMethods={communicationMethods}
                onCommunicationMethodsChange={handleCommunicationChange}
                onCommunicationAllAgree={handleCommunicationAllAgree}
              />
            </Stack>
            <CustomButton disabled={isSubmitDisabled}>
              <FormattedMessage id="signup.register" />
            </CustomButton>
          </Stack>
        )}
        {activeStep === 1 && <CompletionPage isModal={isModal} />}
      </Card>
    </Layout>
  );
}
