import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import DateSelector from "./sections/DateSelector";
import {
  Tabs,
  Tab,
  Card,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TrainService, {
  SearchResult,
  SearchResultParams,
} from "services/trainService";
import useTranslateStation from "hooks/useTranslateStation";
import LoadingSpinner from "components/LoadingSpinner";
import ScheduleItem from "./sections/ScheduleItem";
import Arrow from "components/Arrow";
import Modal from "components/modal/Modal";
import LoginContent from "components/login/LoginContent";
import {
  SearchParams,
  selectSearchParams,
  updateSearchParams,
} from "app/reservationSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import CustomButton from "components/button/CustomButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import useIsScrolling from "hooks/useIsScrolling";
import useDebounce from "hooks/useDebounce";
import { StorageUtils } from "utils/storageUtils";
import useLanguages from "hooks/useLanguages";

type TabType = 0 | 1 | 2 | 3;

// 스케줄 필터링 함수
// TAE CODE: 역무열차종별코드
const filterSchedules = (type: TabType, searchResults: SearchResult[]) => {
  return searchResults.filter((result) => {
    switch (type) {
      case 0:
        return true; // '전체' 탭에서는 모든 스케줄 표시
      case 1:
        return result.stlbTrnClsfCd === "00"; // 'KTX' 탭에서는 KTX 스케줄만 표시
      case 2:
        return ["07", "10"].includes(result.stlbTrnClsfCd); // 'KTX-산천' 탭에서는 KTX-산천, 호남 스케줄 표시
      case 3:
        return result.stlbTrnClsfCd === "16"; // 'KTX-이음' 탭에서는 KTX-이음 스케줄만 표시
      default:
        return true;
    }
  });
};

const reload = (index: TabType) => {
  StorageUtils.TrainTab.set(index);
  StorageUtils.ScrollY.set(window.scrollY);
  window.location.reload();
};

const createSearchParams = (searchParams: SearchParams): SearchResultParams => {
  return {
    abrdDt: searchParams?.date.replaceAll("-", ""),
    dptRsStnCd: searchParams?.departureCode,
    arvRsStnCd: searchParams?.arrivalCode,
    dptTm: searchParams?.departureTime,
    totPsgNum: searchParams?.passengers, // 총 승객 수
    medDvCd: "02", // 인터넷은 "02"로 고정
    nowPgNo: "1", // 현재 페이지 번호
    pgPrCnt: "100", // 페이지당 결과 수 - default "10"
  };
};

const TrainListPage = () => {
  const intl = useIntl();
  const { isKorean, isLanguage } = useLanguages();

  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(selectSearchParams);
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabType>(
    parseInt(StorageUtils.TrainTab.get() ?? "0") as TabType
  );
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [expandedItem, setExpandedItem] = useState<number[]>([]);
  const isScrolling = useIsScrolling();
  const translateStationName = useTranslateStation();
  // const matches = useMediaQuery("(min-width:386px)");
  const matches = useMediaQuery("(min-width:321px)");

  const navigate = useNavigate();
  const debounce = useDebounce();

  // data
  const tabLabels = [
    intl.formatMessage({ id: "trainType.all" }),
    intl.formatMessage({ id: "trainType.ktx" }),
    intl.formatMessage({ id: "trainType.ktxSancheon" }),
    intl.formatMessage({ id: "trainType.ktxEum" }),
  ];

  // API 호출 함수
  const fetchSchedule = useCallback(async () => {
    const params = createSearchParams(searchParams);

    try {
      const searchResult = await TrainService.getSearchResult(params);
      setSearchResults(searchResult); // Update the state with the new search result
    } catch (error) {
      console.error("Error fetching schedule:", error);
      alert("검색 중 오류가 발생했습니다.");
      navigate("/");
    } finally {
      setLoading(false);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    debounce(fetchSchedule);
    // return () => {
    //   clearTimeout(timeoutId);
    // };
  }, [debounce, fetchSchedule]);

  useEffect(() => {
    if (!searchParams.departure) {
      navigate("/");
    }
    // 모달로 로그인한 경우 localStorage 삭제
    StorageUtils.IsLoginModal.remove();

    window.addEventListener("beforeunload", () => {
      reload(selectedTab);
    });
    return () => {
      window.removeEventListener("beforeunload", () => {
        reload(selectedTab);
      });
    };
  }, [navigate, searchParams.departure, selectedTab]);

  // Scroll to previous position before browser (re)painting
  useLayoutEffect(() => {
    if (!loading) {
      window.scroll(0, sessionStorage.yScroll);
    }
  }, [loading]);

  // 날짜 이동
  const handleDateChange = (direction: -1 | 1) => {
    setLoading(true);
    const prevDate = new Date(searchParams.date);
    prevDate.setDate(prevDate.getDate() + direction);

    dispatch(
      updateSearchParams({ date: prevDate.toISOString().split("T")[0] })
    );

    setExpandedItem([]); // Collapse all expanded ScheduleItems
  };

  const handleToggleExpand = (index: number) => {
    if (expandedItem.includes(index)) {
      // If index is already in the list, remove it
      setExpandedItem((prev) => prev.filter((item) => item !== index));
      return;
    }
    // Otherwise, add it to the list
    setExpandedItem((prev) => [...prev, index]);
  };

  const getPageTitle = useCallback(() => {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
      >
        {isLanguage("ur") ? (
          <>
            {translateStationName(searchParams.arrival)}
            <Arrow />
            {translateStationName(searchParams.departure)}
          </>
        ) : (
          <>
            {translateStationName(searchParams.departure)}
            <Arrow />
            {translateStationName(searchParams.arrival)}
          </>
        )}
      </Stack>
    );
  }, [
    isLanguage,
    searchParams.arrival,
    searchParams.departure,
    translateStationName,
  ]);

  return (
    <>
      <Layout text={getPageTitle()}>
        <Card
          sx={{
            border: "none",
            boxShadow: "none",
            bgcolor: theme.background,
            position: "fixed",
            zIndex: 10,
            width: "100%",
            left: 0,
            px: 2,
            pb: 1,
            borderRadius: 0,
            borderBottom: `1px solid ${theme.border.color.light}`,
          }}
        >
          {/* 날짜 선택 컴포넌트 */}
          <DateSelector
            date={searchParams.date}
            onPrev={() => handleDateChange(-1)}
            onNext={() => handleDateChange(1)}
          />
          <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.08) 0 2px 8px 0" }}>
            <Tabs
              value={selectedTab}
              onChange={(_e, newValue) => {
                setExpandedItem([]);
                StorageUtils.TrainTab.set(newValue);
                setSelectedTab(newValue);
              }}
              variant={matches ? "fullWidth" : "scrollable"}
              indicatorColor="primary"
              textColor="primary"
            >
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  sx={{
                    fontWeight: "bold",
                    height: "40px",
                    wordBreak: "keep-all",
                  }}
                />
              ))}
            </Tabs>
          </Card>
        </Card>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <Stack
            justifyContent="start"
            alignItems="center"
            gap={1}
            sx={{ mt: 20 }}
          >
            {filterSchedules(selectedTab, searchResults).length === 0 ? (
              <Stack justifyContent="center" sx={{ minHeight: "50vh" }}>
                <Typography variant="h5">
                  {intl.formatMessage({ id: "fareInquiry.noTrains" })}
                </Typography>
              </Stack>
            ) : (
              filterSchedules(selectedTab, searchResults).map(
                (result, index) => (
                  <ScheduleItem
                    key={result.gdConsItmId}
                    schedule={result}
                    expanded={expandedItem.includes(index)} // Check if index is in the expandedItem list
                    // expanded={expandedItem === index}
                    onToggleExpand={() => handleToggleExpand(index)}
                    setModalVisible={setModalVisible}
                  />
                )
              )
            )}
            {modalVisible && (
              <Modal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
              >
                <LoginContent
                  isModal={true}
                  closeModal={() => setModalVisible(false)}
                />
              </Modal>
            )}
            <CustomButton
              style={{
                position: "fixed",
                bottom: "3vh",
                right: "24px",
                opacity: isScrolling ? 0.05 : 1,
                transition: "all 0.2s ease-in-out",
              }}
              size="medium"
              fullWidth={false}
              onClick={() => reload(selectedTab)}
            >
              {isKorean ? "새로고침" : "Reload"}
              <RefreshIcon />
            </CustomButton>
          </Stack>
        )}
      </Layout>
    </>
  );
};

export default TrainListPage;
