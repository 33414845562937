import { CommunicationType } from "pages/signup-panel/RegistrationTab";
import { SubdomainProvider } from "./providerUtils";

export const makeSignUpFormData = (communicationMethods: CommunicationType) => {
  const formData = new FormData();

  const sub = window.location.host.split(".")[0]; // 고객사 정보
  if (sub === SubdomainProvider.Travelwallet || sub === "test2") {
    formData.append("provider", "TravelWallet");
  } else if (sub === SubdomainProvider.Paybooc) {
    formData.append("provider", "Paybooc");
  }

  formData.append("isReceivingEmail", String(communicationMethods.email));
  formData.append("isReceivingSms", String(communicationMethods.sms));
  // formData.append("isReceivingPush", String(communicationMethods.push));
  formData.append("isReceivingTalk", String(communicationMethods.kakao));

  return formData;
};
