import { Card, Typography, Box, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import AlarmIcon from "@mui/icons-material/Alarm";
import ForumIcon from "@mui/icons-material/Forum";

const CustomerCenterPage = () => {
  const intl = useIntl();
  // const goToNotice = () => {
  //   navigate("/notice"); // Navigate back on back button click
  // };
  return (
    <Layout text={intl.formatMessage({ id: "cs.title" })}>
      <Stack sx={{ width: "100%" }} alignItems="center">
        <Card sx={{ maxWidth: 600, minWidth: 400, my: 2 }}>
          <Box sx={{ p: 2 }}>
            <Stack alignItems="center" gap={3}>
              <Stack alignItems="center">
                <ForumIcon sx={{ my: 2 }} fontSize="large" />
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="cs.message" />
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="cs.train" />: help@hcclab.com
                </Typography>
                {/* <Typography variant="body1" gutterBottom>
                  <FormattedMessage id="cs.flight" />:
                  dreamdt@hyundaidreamtour.com
                </Typography> */}
              </Stack>
              <Stack alignItems="center">
                <AlarmIcon sx={{ my: 2 }} fontSize="large" />
                <Typography variant="body2">
                  <FormattedMessage id="cs.weekday" /> 09:00~18:00
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="cs.lunch" /> 12:00~13:00
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <FormattedMessage id="cs.holiday" />
                </Typography>
              </Stack>
              {/* <Button
            onClick={goToNotice}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            >
            {intl.formatMessage({ id: "cs.notice" })}
          </Button> */}
            </Stack>
          </Box>
        </Card>
      </Stack>
    </Layout>
  );
};

export default CustomerCenterPage;
