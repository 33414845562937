import {
  Card,
  TextField,
  Typography,
  Tabs,
  Tab,
  Container,
  Stack,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import Layout from "components/layout/Layout";
import useInputs from "hooks/useInputs";
import useValidate from "hooks/useValidate";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService, { FindObjective } from "services/authService";

const initialForm = {
  name: "",
  phone: "",
};

const initialValidState = {
  name: true,
  phone: true,
};

const FindIdPage = () => {
  const intl = useIntl();
  const { isModal } = useLocation().state as { isModal: boolean };
  const navigate = useNavigate();

  const objective: FindObjective = "findId";
  const { form, onChange } = useInputs(initialForm);
  const [validState, validate] = useValidate(initialValidState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name !== "name") {
      validate(e);
    }
    onChange(e);
  };

  // 폼 제출 핸들러
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const emails = await AuthService.findEmail(
        String(form.name),
        String(form.phone)
      );
      navigate("/confirmation", {
        state: { emails, objective, isModal },
      });
    } catch (error) {
      console.error("이메일 찾기 오류:", error);
      throw error;
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "user.findId" })}>
      <Container maxWidth="sm" sx={{ marginTop: 0, p: 0 }}>
        <Tabs
          value={"phone"}
          variant="fullWidth"
          sx={{
            height: "54px",
            // borderBottom: `1px solid ${alpha(theme.border.color.main, 0.3)}`,
            borderBottom: `1px solid #3333}`,
          }}
        >
          <Tab
            label={intl.formatMessage({ id: "forgot.withPhone" })}
            value="phone"
            sx={{ height: "54px", fontWeight: "bold" }}
          />
        </Tabs>
        <Card sx={{ p: 2, my: 2 }}>
          <Stack alignItems="center">
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <Stack spacing={2} sx={{ mb: 3 }}>
                <Stack gap={0.7}>
                  <Typography variant="h6">
                    <FormattedMessage id="signup.name" />{" "}
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Typography>
                  <TextField
                    label={intl.formatMessage({ id: "forgot.enterName" })}
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    size="small"
                  />
                </Stack>
                <Stack gap={0.7}>
                  <Typography variant="h6">
                    <FormattedMessage id="signup.phone" />{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    type="tel"
                    name="phone"
                    label={intl.formatMessage({ id: "forgot.enterPhone" })}
                    value={form.phone}
                    onChange={handleChange}
                    required
                    size="small"
                    error={!!form.phone && !validState.phone}
                    helperText={
                      form.phone &&
                      !validState.phone && (
                        <span>
                          <FormattedMessage id="forgot.phoneInvalid" />
                        </span>
                      )
                    }
                  />
                </Stack>
              </Stack>

              <CustomButton disabled={false}>
                <FormattedMessage id="user.findId" />
              </CustomButton>
            </form>
          </Stack>
        </Card>
      </Container>
    </Layout>
  );
};

export default FindIdPage;
