import { PaymentMethod } from "const/paymentMethod";
import {
  ProviderUtils,
} from "./providerUtils";

export const getDefaultPaymentMethod = () => {
  if (ProviderUtils.isGME) {
    return PaymentMethod.GME;
  }

  if (ProviderUtils.isHanpass) {
    return PaymentMethod.HANPASS;
  }

  if (ProviderUtils.isMame) {
    return PaymentMethod.OVERSEAS;
  }

  return PaymentMethod.CREDIT;
};
