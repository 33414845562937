import { SocialLoginProvider } from "components/button/SocialLoginButton";

const enum LocalStorageKey {
  FIELD_NAME = "fieldName",
  RECENT_SEARCHES = "recentSearches",
  IS_LOGIN_MODAL = "isLoginModal",
  SAVED_LOGIN_ID = "savedLoginId",
  RECENT_LOGIN_METHOD = "recentLoginMethod",
}

const enum SessionStorageKey {
  HANPASS_BACK = "hanpass_back",
  TRAIN_TAB = "tab",
  Y_SCROLL = "yScroll",
}

type BooleanishString = "true" | "false";

export class StorageUtils {
  // localStorage
  static StationFieldName = class {
    static KEY = LocalStorageKey.FIELD_NAME;

    static get() {
      return localStorage.getItem(this.KEY);
      // return localStorage.getItem(StorageUtils.FieldName.KEY);
    }

    static set(value: "departure" | "arrival") {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static RecentSearches = class {
    static KEY = LocalStorageKey.RECENT_SEARCHES;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: string) {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static IsLoginModal = class {
    static KEY = LocalStorageKey.IS_LOGIN_MODAL;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: boolean | BooleanishString) {
      localStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static SavedLoginId = class {
    static KEY = LocalStorageKey.SAVED_LOGIN_ID;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: string) {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static RecentLoginMethod = class {
    static KEY = LocalStorageKey.RECENT_LOGIN_METHOD;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: SocialLoginProvider | "DEFAULT_FORM") {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  // sessionStorage
  static HanpassBack = class {
    static KEY = SessionStorageKey.HANPASS_BACK;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: string) {
      sessionStorage.setItem(this.KEY, value);
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };

  static TrainTab = class {
    static KEY = SessionStorageKey.TRAIN_TAB;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: string | number) {
      sessionStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };

  static ScrollY = class {
    static KEY = SessionStorageKey.Y_SCROLL;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: number) {
      sessionStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };
}
