import {
  Grid,
  TextField,
  Typography,
  useTheme,
  Box,
  Stack,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import CardLayout from "components/layout/CardLayout";
import { useAppSelector } from "app/hooks";
import { selectTrainParams } from "app/reservationSlice";
import { ProviderUtils } from "utils/providerUtils";
import CustomButton from "components/button/CustomButton";
import TrainService from "services/trainService";
import { useParams } from "react-router-dom";
import { OrderType } from "types/orderType";
import useLanguages from "hooks/useLanguages";

interface OrderSummarySectionProps {
  reservationDetails: OrderType;
  point: number;
  pointParkPoint: number;
  setPointParkPoint: React.Dispatch<React.SetStateAction<number>>;
  setPointParkUrl: React.Dispatch<React.SetStateAction<string>>;
  hanpassCouponDiscountAmount?: number;
}

const OrderSummarySection = ({
  reservationDetails,
  point,
  pointParkPoint,
  setPointParkPoint,
  setPointParkUrl,
  hanpassCouponDiscountAmount = 0,
}: OrderSummarySectionProps) => {
  const {
    originalPrice: totalPrice,
    totalDiscount: discount,
    totalCommission: commission,
    totalPrice: finalPrice,
  } = reservationDetails;

  const intl = useIntl();
  const { isKorean } = useLanguages();
  const theme = useTheme();

  const { orderId = "" } = useParams();
  const formatPrice = useFormatPrice();
  const { costDetails } = useAppSelector(selectTrainParams);
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  )!;

  // data
  const totalDiscount = discount + hanpassCouponDiscountAmount;

  const checkPointParkPoint = async () => {
    const redirectPath = "pointpark/complete";

    try {
      const { data } = await TrainService.checkPoint(orderId, redirectPath);
      setPointParkUrl(data);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelPointParkPoint = async () => {
    try {
      await TrainService.cancelPoint(orderId);
      setPointParkPoint(0);
    } catch (error) {
      console.error(error);
    }
  };

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        {intl.formatMessage({ id: "payment.priceInfo" })}
      </Typography>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <p>예상 적립금: </p>
        {formatPrice(
          Math.round(
            adultPriceDetail?.csmrFare *
              (1 - adultPriceDetail?.sellDscntRt / 100) *
              (adultPriceDetail?.pointSavingRt / 100)
          )
        )}
      </Stack>
    );
  };

  return (
    <CardLayout
      header={getHeader()}
      additionalInfo={
        adultPriceDetail?.pointSavingRt && ProviderUtils.isPointProvider
          ? getAdditionalInfo()
          : undefined
      }
    >
      {/* 복합상품금액 표시 */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>
            <FormattedMessage id="payment.combined" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            sx={{
              textDecoration:
                ProviderUtils.isDiscountProvider && totalDiscount
                  ? "line-through"
                  : "none",
            }}
          >
            {formatPrice(totalPrice)}
          </Typography>
        </Grid>
      </Grid>

      {/* 할인 금액 표시 */}
      {totalDiscount > 0 && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ color: theme.palette.error.main }}
          >
            <Grid item>
              <Typography>
                <FormattedMessage id="payment.discount" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {totalDiscount ? "-" : ""}
                {formatPrice(totalDiscount ?? 0)}
              </Typography>
            </Grid>
            {ProviderUtils.isHanpass && (
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  paddingInlineStart: 1,
                  color: theme.palette.black.main,
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ textAlign: "start" }}>
                    * Kakao T {isKorean ? "할인" : "discount"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ textAlign: "end" }}>
                    {formatPrice(discount ?? 0)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ textAlign: "start" }}>
                    * {isKorean ? "한패스 할인" : "Hanpass discount"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ textAlign: "end" }}>
                    {formatPrice(hanpassCouponDiscountAmount)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </>
      )}

      {/* 수수료 */}
      {commission > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="payment.commission" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{formatPrice(commission ?? 0)}</Typography>
          </Grid>
        </Grid>
      )}

      {/* 적립금 */}
      {point > 0 && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ color: theme.palette.error.main }}
        >
          <Grid item>
            <Typography>
              <FormattedMessage id="point.point" />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>-{formatPrice(point)}</Typography>
          </Grid>
        </Grid>
      )}

      {/* 포인트파크 */}
      {!ProviderUtils.provider && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={5} sx={{ textAlign: "start" }}>
            <Typography sx={{ wordBreak: "keep-all" }}>
              <FormattedMessage id="pointpark.pointpark" />
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="end" gap={0.5}>
              <TextField
                value={pointParkPoint}
                disabled
                fullWidth
                InputProps={{
                  sx: {
                    height: "48px",
                    "& .MuiInputBase-input": {
                      textAlign: "end",
                    },
                  },
                  startAdornment: pointParkPoint > 0 ? "-" : "",
                  endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
                }}
              />
              <Box sx={{ width: "40%" }}>
                <CustomButton
                  size="large"
                  onClick={
                    pointParkPoint ? cancelPointParkPoint : checkPointParkPoint
                  }
                >
                  {pointParkPoint
                    ? intl.formatMessage({ id: "pointpark.cancel" })
                    : intl.formatMessage({ id: "pointpark.apply" })}
                </CustomButton>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      )}

      {/* 총주문금액 표시 */}
      <Grid container justifyContent="space-between" alignItems="end">
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="payment.totalPrice" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="error" sx={{ fontWeight: "bold" }}>
            {formatPrice(
              finalPrice - point - pointParkPoint - hanpassCouponDiscountAmount
            )}
          </Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default OrderSummarySection;
