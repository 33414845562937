import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  Fab,
  Grid,
  Button,
  useTheme,
  Card,
  TextField,
} from "@mui/material";
import TrainFlightTabs from "components/tabs/TrainFlightTabs";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useIntl } from "react-intl";
import LoadingSpinner from "components/LoadingSpinner";
import { styled } from "@mui/material/styles";
import CustomButton from "components/button/CustomButton";
import useTranslateStation from "hooks/useTranslateStation";
import {
  deleteReservation,
  selectSearchParams,
  updateSearchParams,
} from "app/reservationSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/ko";
import { StorageUtils } from "utils/storageUtils";
import useLanguages from "hooks/useLanguages";

const convertTimeStringToMoment = (inputString) => {
  const timeString = inputString
    ? `${inputString.slice(0, 2)}:${inputString.slice(
        2,
        4
      )}:${inputString.slice(4)}`
    : "00:00:00";
  return moment(timeString, "HH:mm:ss");
};

const BookingForm = () => {
  const theme = useTheme();
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(selectSearchParams);

  const translateStationName = useTranslateStation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    StorageUtils.StationFieldName.remove();

    // Reset reservation state in redux when refreshing
    if (!state?.isSearching) {
      dispatch(deleteReservation());
    }
  }, [dispatch, state?.isSearching]);

  // 입력 필드 클릭 이벤트 핸들러
  const handleInputClick = (e) => {
    const { name } = e.target;
    const route = ["departure", "arrival"].includes(name)
      ? "/station-list"
      : `/select-${name}`;

    navigate(route, {
      state: { fieldName: name },
      replace: true,
    });
  };

  // 출발지와 도착지 역이름,역코드 교환 이벤트 핸들러
  const handleSwap = () => {
    const newArrival = searchParams?.departure;
    const newArrivalCode = searchParams?.departureCode;
    const newDeparture = searchParams?.arrival;
    const newDepartureCode = searchParams?.arrivalCode;

    dispatch(
      updateSearchParams({
        arrival: newArrival,
        arrivalCode: newArrivalCode,
        departure: newDeparture,
        departureCode: newDepartureCode,
      })
    );
  };

  const getPassengerText = () => {
    const adults = searchParams?.adults ?? 1;
    const children = searchParams?.children ?? 0;
    const textParts = [];

    if (adults > 0) {
      textParts.push(
        `${intl.formatMessage(
          { id: "passengerSelection.adultsNumber" },
          { number: `${adults}` }
        )}`
      );
    }

    if (children > 0) {
      textParts.push(
        `${intl.formatMessage(
          { id: "passengerSelection.childrenNumber" },
          { number: `${children}` }
        )}`
      );
    }

    // 성인과 어린이 모두 0명일 경우 기본 텍스트 표시
    return (
      textParts.join(", ") ||
      intl.formatMessage({ id: "passengerSelection.passengers" })
    );
  };

  const handleDateChange = (value) => {
    if (value?.isBefore(moment(), "day")) {
      alert("오늘 이전의 날짜는 선택할 수 없습니다.");
      return;
    }

    dispatch(
      updateSearchParams({
        date: value?.format("YYYY-MM-DD") ?? "",
      })
    );
  };

  const handleDepartureTimeChange = (value) => {
    dispatch(
      updateSearchParams({
        departureTime: value?.format("HHmmss") ?? "000000",
      })
    );
  };

  const isFormComplete = () => {
    return (
      searchParams?.departure &&
      searchParams?.arrival &&
      searchParams?.date &&
      searchParams?.passengers
    );
  };

  // 폼 제출 이벤트 핸들러
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // 필수 필드 검사
    if (!isFormComplete()) {
      alert("모든 필드를 채워주세요.");
      setLoading(false);
      return;
    }

    navigate("/search-results");
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Card sx={{ m: 2, py: 2 }}>
          <Stack
            component="form"
            onSubmit={handleSubmit}
            gap={2}
            marginX={2}
            sx={{
              overflow: "auto",
            }}
          >
            <TrainFlightTabs value={0} />
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                container
                sx={{ position: "relative", width: "100%" }}
              >
                <Grid item xs={6}>
                  {/* "Departure station" */}
                  <StationInput
                    locale={intl.locale}
                    type="text"
                    name="departure"
                    readOnly
                    value={translateStationName(searchParams?.departure)}
                    onClick={handleInputClick}
                    placeholder={intl.formatMessage({
                      id: "trainMain.from",
                    })}
                    id="departure"
                  />
                </Grid>
                {/* Swap Icon */}
                <Fab
                  size="small"
                  onClick={handleSwap}
                  sx={{
                    boxShadow: "none",
                    position: "absolute",
                    left: "calc(50% - 15px)",
                    top: "calc(50% - 15px)",
                    backgroundColor: "transparent",
                    color: theme.palette.text.primary,
                  }}
                >
                  <SwapHorizIcon />
                </Fab>
                <Grid item xs={6}>
                  <StationInput
                    // "Arrival station"
                    locale={intl.locale}
                    type="text"
                    name="arrival"
                    readOnly
                    value={translateStationName(searchParams?.arrival)}
                    onClick={handleInputClick}
                    placeholder={intl.formatMessage({
                      id: "trainMain.to",
                    })}
                    id="arrival"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {/* Customer count input */}
                <Button
                  name="passengers"
                  onClick={handleInputClick}
                  fullWidth
                  style={{
                    border: `1px solid ${theme.border.color.main}`,
                    color: theme.palette.text.primary,
                  }}
                  sx={{
                    color: "black",
                    height: 64,
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  {getPassengerText()}
                </Button>
              </Grid>

              <Grid item xs={6}>
                {/* Date input */}
                <DateInput
                  // label={
                  //   !searchParams.date
                  //     ? intl.formatMessage({
                  //         id: "trainMain.selectADate",
                  //       })
                  //     : ""
                  // }
                  value={searchParams.date ? moment(searchParams.date) : null}
                  onChange={handleDateChange}
                  closeOnSelect={true}
                  minDate={moment().add(2, "days")}
                  maxDate={moment().add(1, "months")}
                  disableHighlightToday
                  displayStaticWrapperAs="mobile"
                  slotProps={{
                    toolbar: {
                      toolbarFormat: isKorean ? "YY년 MM월 DD일" : "MMM DD, YY",
                      toolbarPlaceholder: "",
                      hidden: false,
                    },
                    actionBar: {
                      actions: [],
                    },
                    textField: {
                      placeholder: intl.formatMessage({
                        id: "trainMain.selectADate",
                      }),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Departure time input */}
                <DepartureTime
                  label={intl.formatMessage({ id: "booking.departureTime" })}
                  views={["hours", "minutes"]}
                  defaultValue={convertTimeStringToMoment(
                    searchParams.departureTime
                  )}
                  onChange={handleDepartureTimeChange}
                  closeOnSelect={true}
                  slotProps={{
                    actionBar: {
                      actions: ["cancel"],
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* Train search button*/}
            <CustomButton
              id="trainMain.search"
              disabled={!isFormComplete()}
              color="secondary"
              style={{ opacity: 0.8, fontWeight: 500 }}
            />
          </Stack>
        </Card>
      )}
    </>
  );
};

export default BookingForm;

const DateInput = styled(MobileDatePicker)`
  width: 100%;

  & .MuiInputBase-root {
    height: 64px;
  }
  & .MuiInputBase-input {
    text-align: center;
    font-weight: bold;
  }

  & label.MuiFormLabel-root {
    width: 100%;
    text-align: center;
  }

  & label.MuiFormLabel-root {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .MuiOutlinedInput-notchedOutline legend {
    display: hidden;
  }
`;

const DepartureTime = styled(MobileTimePicker)`
  width: 100%;

  & .MuiInputBase-root {
    height: 64px;
  }
  & .MuiInputBase-input {
    text-align: center;
    font-weight: bold;
  }
`;

const StationInput = styled(TextField)(({ id, locale, theme }) => {
  const commonStyle = {
    width: "100%",
    height: "64px",
    border: `1px solid ${theme.border.color.main}`,
    boxSizing: "border-box",
    fontSize: "1.125rem",
    fontWeight: "bold",
    textAlign: "center",
    wordBreak: "keep-all",

    "&::placeholder": {
      fontWeight: "normal",
      color: theme.palette.grey,
    },
    color: theme.palette.text.primary,

    borderInlineEnd: id === "departure" && `none`,
    borderInlineStart: id === "arrival" && `none`,
  };

  const fromBorderRadiusStyle =
    locale !== "ur"
      ? {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        }
      : {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        };

  const toBorderRadiusStyle =
    locale !== "ur"
      ? {
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        }
      : {
          borderTopLeftRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
        };

  return {
    width: "100%",
    overflow: "hidden",

    "& .MuiInputBase-input": {
      ...commonStyle,
      ...(id === "departure" ? fromBorderRadiusStyle : toBorderRadiusStyle),
    },
    "& .MuiOutlinedInput-input": {
      border: `1px solid ${theme.border.color.main}`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  };
});
