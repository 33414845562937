import { PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ProviderUtils } from "utils/providerUtils";

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    complete: true;
    pending: true;
    cancelled: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    banner: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    mode: PaletteMode;
    black: { main: string; dark: string };
    white: { main: string; dark: string };
    orderStatus: Palette["primary"];
  }

  interface PaletteOptions {
    mode?: PaletteMode;
    black?: { main?: string; dark?: string };
    white?: { main?: string; dark?: string };
    orderStatus?: PaletteOptions["primary"];
  }

  interface Theme {
    background: string;
    border: {
      color: {
        main: string;
        light: string;
      };
      radius: {
        big: number;
        medium: number;
        small: number;
      };
    };
  }

  interface ThemeOptions {
    background?: string;
    border?: {
      color?: {
        main?: string;
        light?: string;
      };
      radius?: {
        big?: number;
        medium?: number;
        small?: number;
      };
    };
  }
}

const ChipColorVariant = {
  complete: {
    main: ProviderUtils.isLottecard ? "#9c8a80" : "#5A72A0",
    light: "#F3F3F3",
    dark: ProviderUtils.isLottecard ? "#9c8a80" : "#5A72A0",
    contrastText: "#FFF",
  },
  pending: {
    main: "#5A72A0",
    light: "#F3F3F3",
    dark: "#5A72A0",
    contrastText: "#FFF",
  },
  cancelled: {
    main: "#ea7869",
    light: "#F3F3F3",
    dark: "#ea7869",
    contrastText: "#FFF",
  },
};

const commonTheme = {
  shape: {
    borderRadius: 12,
  },
  palette: {
    // text: {
    //   primary: "#1C313A",
    // },
    error: {
      main: "#ee1100",
      light: "#FEE9ED",
      dark: "#A8001B",
    },

    grey: {
      100: "#F3F3F3",
    },

    banner: {
      main: "#FF1744",
    },

    action: {
      disabledBackground: "#E6E6E6", // 'set color of background here'
      disabled: "#A9A9A9", // 'set color of text here'
    },

    ...ChipColorVariant,
  },
};

const radius = {
  big: 16,
  medium: 8,
  small: 4,
};

// background, border, shape, palette
const commonLightTheme = {
  ...commonTheme,
  background: "#F8F8F8",
  border: {
    color: {
      main: "#66666666",
      light: "#66666630",
    },
    radius,
  },
  palette: {
    mode: "light" as PaletteMode,
    ...commonTheme.palette,
  },
};

// background, border, shape, palette
const commonDarkTheme = {
  ...commonTheme,
  background: "#121212",
  border: {
    color: {
      main: "#666666",
      light: "#66666630",
    },
    radius,
  },
  palette: {
    mode: "dark" as PaletteMode,
    ...commonTheme.palette,
  },
};

const lightPalette = {
  black: {
    main: "#1C313A",
  },

  white: {
    main: "#FFF",
  },

  text: {
    primary: ProviderUtils.isLottecard ? "#222222" : "#1C313A",
  },
};

const darkPalette = {
  black: {
    main: "#DDD",
  },

  white: {
    main: "#222222",
  },
};

const defaultPalette = {
  primary: {
    main: "#3F4094",
    light: "#D7E0F7",
  },
  secondary: {
    main: "#5A72A0",
  },
  tertiary: {
    main: "#D7E0F7",
    light: "#3F4094",
  },
  homeBanner: {
    bgColor: "#D7E0F7",
    textColor: "#3F4094",
  },
};

const lightPayboocPalette = {
  primary: {
    main: "#FF1744",
    light: "#FEE9ED",
  },
  secondary: {
    main: "#1a3a6d",
  },
  tertiary: {
    main: "#FEE9ED",
    light: "#FF1744",
  },
  homeBanner: {
    bgColor: "#FEE9ED",
    textColor: "#FF1744",
  },
};

const darkPayboocPalette = {
  primary: {
    main: "#FF1744",
    light: "#FEE9ED",
  },
  secondary: {
    main: "#5A72A0",
  },
  tertiary: {
    main: "#FEE9ED",
    light: "#FF1744",
  },
  homeBanner: {
    bgColor: "#FEE9ED",
    textColor: "#FF1744",
  },
};

const lightGMEPalette = {
  primary: {
    main: "#FD4949",
    light: "#FEE9ED",
  },
  secondary: {
    main: "#FD4949",
  },
  tertiary: {
    main: "#FEE9ED",
    light: "#FD4949",
  },
  homeBanner: {
    bgColor: "#FEE9ED",
    textColor: "#FD4949",
  },
};

const lightLotteCardPalette = {
  primary: {
    main: "#206CFE",
    light: "#D7E0F7",
  },
  secondary: {
    main: "#206CFE",
  },
  tertiary: {
    main: "#3376F6",
    light: "#FFFFFF",
  },
  homeBanner: {
    bgColor: "#DCF4C5",
    textColor: "#544944",
  },
};

const lightComponents = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: `0.1px solid ${commonLightTheme.border.color.light}`,
          // boxShadow: "none",
          boxShadow: "rgba(0, 0, 0, 0.08) 0 2px 8px 0",
        },
      },
    },
  },
};

const darkComponents = {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: `0.5px solid ${commonDarkTheme.border.color.main}`,
          // boxShadow: "none",
          boxShadow: "rgba(0, 0, 0, 0.08) 0 2px 8px 0",
        },
      },
    },
  },
};

export const lightDefaultTheme = createTheme({
  ...commonLightTheme,
  palette: {
    ...lightPalette,
    ...defaultPalette,
    ...commonLightTheme.palette,
  },
  ...lightComponents,
});

export const darkDefaultTheme = createTheme({
  ...commonDarkTheme,
  palette: {
    ...darkPalette,
    ...defaultPalette,
    ...commonDarkTheme.palette,
    primary: {
      ...defaultPalette.primary,
      main: defaultPalette.secondary.main,
    },
  },
  ...darkComponents,
});

export const lightPayboocTheme = createTheme({
  ...commonLightTheme,
  palette: {
    ...lightPalette,
    ...lightPayboocPalette,
    ...commonLightTheme.palette,
  },
  ...lightComponents,
});

export const darkPayboocTheme = createTheme({
  ...commonDarkTheme,
  palette: {
    ...darkPalette,
    ...darkPayboocPalette,
    ...commonDarkTheme.palette,
  },
  ...darkComponents,
});

export const lightGMETheme = createTheme({
  ...commonLightTheme,
  palette: {
    ...lightPalette,
    ...lightGMEPalette,
    ...commonLightTheme.palette,
  },
  ...lightComponents,
});

export const lightLotteCardTheme = createTheme({
  ...commonLightTheme,
  palette: {
    ...lightPalette,
    ...lightLotteCardPalette,
    ...commonLightTheme.palette,
  },
  ...lightComponents,
});

// const deviceSizes = {
//   mobile: 375,
//   tablet: 768,
//   laptop: 768,
//   // 1024,
// };

// const device = {
//   mobile: `screen and (max-width: ${deviceSizes.mobile}px)`,
//   tablet: `screen and (max-width: ${deviceSizes.tablet}px)`,
//   laptop: `screen and (max-width: ${deviceSizes.laptop}px)`,
// };

// const theme = {
//   device,
// };
