import { ProviderUtils } from "utils/providerUtils";

let apiBaseUrl: string;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case "prod":
    if (!ProviderUtils.provider) {
      apiBaseUrl = "https://api.lacha.co.kr";
      break;
    }
    apiBaseUrl = "https://apinew.hcclab.co.kr";
    break;
  case "dev":
    apiBaseUrl = "https://devfo.hcclab.com";
    break;
  case "staging":
    apiBaseUrl = "https://staging.hcclab.co.kr";
    break;
  default:
    apiBaseUrl = "http://localhost:8081";
    apiBaseUrl = "https://devfo.hcclab.com";
    break;
}

export default apiBaseUrl;
