type RegexType = {
  [key: string]: RegExp;
};

const REGEX: RegexType = {
  password: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/,
  confirmPassword: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/,
  phone: /^\+?\d{9,14}$/,
  email: /^[a-zA-Z0-9+\-_.]+@([\w-]+\.)+[\w-]{2,4}$/,
  prefix: /^[a-zA-Z0-9+\-_.]+$/,
  domain: /^([\w-]+\.)+[\w-]{2,4}$/,
  typedDomain: /^([\w-]+\.)+[\w-]{2,4}$/,
  birthDate: /^(19|20|21)\d{2}((0[1-9])|(1[0-2]))(0[1-9]|[1-2][0-9]|3[0-1])$/,
  name: /(.)+/,
} as const;

export default REGEX;
