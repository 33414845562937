import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AuthService from "services/authService";
import { deleteReservation } from "app/reservationSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import TokenService from "services/tokenService";
import { useAppDispatch } from "app/hooks";
import { deleteUser } from "app/userSlice";
import { ProviderUtils } from "utils/providerUtils";

const PrivateRoute = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const response = await AuthService.isLoggedIn();
      setIsLoggedIn(response);
      setIsChecking(false);
    };
    checkLoggedIn();
  });

  const resetStore = () => {
    dispatch(deleteUser());
    dispatch(deleteReservation());
    dispatch(deleteMyOrders());
    TokenService.removeAccessToken();
  };

  if (!isChecking && !isLoggedIn) {
    resetStore();
    // 로그인하지 않은 경우, 로그인 페이지로 리다이렉트합니다.
    if (ProviderUtils.isHanpass) {
      window.location.href = "http://finish";
      return;
    } else {
      // `state`를 사용하여 리다이렉트 후 원래 페이지로 돌아올 수 있도록 합니다.
      return (
        <Navigate to="/login" state={{ from: location.pathname }} replace />
      );
    }
  }

  return !isChecking && isLoggedIn && <Outlet />; // 로그인한 경우, 해당 컴포넌트를 렌더링합니다.
};

export default PrivateRoute;
