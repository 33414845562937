import { Container, Stack, Typography } from "@mui/material";
import QRCode from "components/QRCode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import TickerNotice from "./components/TickerNotice";
import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import { SeatDetails } from "components/seat/Seat";
import { NumericString } from "types/numericString";
import LoadingSpinner from "components/LoadingSpinner";
import useFormatDuration from "hooks/useFormatDuration";
import PaymentService from "services/paymentService";
import TicketHeader from "./components/TicketHeader";
import TicketAdditionalInfo from "./components/TicketAdditionalInfo";
import TicketContent from "./components/TicketContent";
import CancelIcon from "@mui/icons-material/Cancel";
import useLanguages from "hooks/useLanguages";
import LanguageSelectIcon from "components/language_selector/LanguageSelectIcon";

const getDateTimeString = (date: NumericString, time?: NumericString) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  const hour = time?.slice(0, 2);
  const minute = time?.slice(2, 4);

  return `${year}-${month}-${day}${time ? `T${hour}:${minute}:00` : ""}`;
};

const calculateDiffInMinutes = (
  departureTime: NumericString,
  arrivalTime: NumericString
) => {
  const departureTimeInMinutes1 =
    parseInt(departureTime.slice(0, 2)) * 60 + parseInt(departureTime.slice(2));
  const arrivalTimeInMinutes2 =
    parseInt(arrivalTime.slice(0, 2)) * 60 + parseInt(arrivalTime.slice(2));

  let diff = arrivalTimeInMinutes2 - departureTimeInMinutes1;

  // Adjust for crossing midnight
  if (diff < 0) {
    diff += 24 * 60;
  }

  return diff;
};

export interface KTXReservationData {
  rsvItmList: {
    psgTpNm: "어린이" | "어른";
    rsvQnty: number;
    qrCode: string;
    dptStnNm: string;
    arvStnNm: string;
    utlClsDt: NumericString; // arrival date
    utlClsHm: NumericString; // arrival time
    utlStDt: NumericString; // departure date
    utlStHm: NumericString; // departure time
    trnNo: string;
  }[];
  trnSeatList: {
    scarNo: string;
    seatNo: string;
  }[];
  rsvSttCd: "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10"; // 예약상태코드 - 03: 예약 확정, 06: 반환
  stlSttCd: "01" | "02" | "03" | "04"; // 결제 상세 코드 - 03: 결제 취소, 04: 결제 완료
}

const AlimTalkTicket = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const formatSeatDetails = useFormatSeatDetails();
  const formatDuration = useFormatDuration();

  const [reservationData, setReservationData] = useState<KTXReservationData>();
  const { ktxNumber } = useParams();
  const stationDetails = {
    departure: reservationData?.rsvItmList[0].dptStnNm || "",
    arrival: reservationData?.rsvItmList[0].arvStnNm || "",
    startDate: reservationData
      ? getDateTimeString(
          reservationData?.rsvItmList[0].utlStDt,
          reservationData?.rsvItmList[0].utlStHm
        )
      : "",
    endDate: reservationData
      ? getDateTimeString(
          reservationData?.rsvItmList[0].utlClsDt,
          reservationData?.rsvItmList[0].utlClsHm
        )
      : "",
  };

  const travelDetails = {
    trainNumber: reservationData?.rsvItmList[0].trnNo || "",
    duration: reservationData ? (
      formatDuration(
        calculateDiffInMinutes(
          reservationData?.rsvItmList[0].utlStHm,
          reservationData?.rsvItmList[0].utlClsHm
        )
      )
    ) : (
      <></>
    ),
  };

  useEffect(() => {
    const getReservationInfo = async () => {
      if (ktxNumber) {
        const data = await PaymentService.getAlimTalkReservationDetail(
          ktxNumber
        );
        setReservationData(data);
      }
    };
    getReservationInfo();
  }, [ktxNumber]);

  if (reservationData?.stlSttCd === "04") {
    const getHeader = () => {
      return (
        reservationData && (
          <TicketHeader
            dateTime={getDateTimeString(reservationData?.rsvItmList[0].utlStDt)}
            qrCode={
              <QRCode
                src={`data:image/png;base64,${reservationData?.rsvItmList[0].qrCode}`}
              />
            }
          />
        )
      );
    };

    const getAdditionalInfo = () => {
      const getPassengerDetails = () => {
        const adultCount =
          reservationData?.rsvItmList.find(
            (passenger) => passenger.psgTpNm === "어른"
          )?.rsvQnty || 0;
        const childrenCount =
          reservationData?.rsvItmList.find(
            (passenger) => passenger.psgTpNm === "어린이"
          )?.rsvQnty || 0;

        return intl.formatMessage(
          { id: "booking.passengersDetail" },
          {
            total: adultCount + childrenCount,
            adults: adultCount,
            children: childrenCount,
          }
        );
      };

      const getSeatDetails = () => {
        return reservationData?.trnSeatList.map((seat) => {
          return {
            scarNo: seat.scarNo,
            seatNo: seat.seatNo,
          } as SeatDetails;
        })!;
      };

      return (
        <TicketAdditionalInfo
          passengerDetails={getPassengerDetails()}
          seatDetails={formatSeatDetails(getSeatDetails())}
        />
      );
    };

    return reservationData ? (
      <Container sx={{ p: 2, wordBreak: "keep-all" }}>
        <Stack sx={{ mb: 2 }}>
          <LanguageSelectIcon type="ENG" />
        </Stack>
        <TicketContent
          stationDetails={stationDetails}
          travelDetails={travelDetails}
          ticketHeader={getHeader()}
          ticketAdditionalInfo={getAdditionalInfo()}
        />

        <TickerNotice />
      </Container>
    ) : (
      <LoadingSpinner />
    );
  }

  return reservationData ? (
    <Container sx={{ p: 2, wordBreak: "keep-all" }}>
      <Stack sx={{ mb: 2, position: "absolute" }}>
        <LanguageSelectIcon type="ENG" />
      </Stack>
      <Stack
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: "100%", height: "90dvh" }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ width: "100%", textAlign: "center" }}
        >
          <CancelIcon color="error" sx={{ fontSize: 120 }} />
          <Typography
            variant="h6"
            sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
          >
            {isKorean ? "취소된 승차권입니다." : "Cancelled train ticket."}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  ) : (
    <LoadingSpinner />
  );
};

export default AlimTalkTicket;
