import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { deleteMyOrders } from "app/myOrdersSlice";
import { updatePoint } from "app/userSlice";
import CardLayout from "components/layout/CardLayout";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PaymentService, {
  CancelFeeCheckResponse,
} from "services/paymentService";
import UserService from "services/userService";
import { OrderType } from "types/orderType";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CancelInfoSummarySection from "pages/order-history-panel/order-cancel-panel/sections/CancelInfoSummarySection";
import CancelTermSection from "pages/order-history-panel/history-detail-panel/sections/CancelTermSection";
import CustomButton from "components/button/CustomButton";
import LoadingSpinner from "components/LoadingSpinner";

const useCancelReservation = (reservationDetails: OrderType | null) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCancelProcessing, setIsCancelProcessing] = useState(false);
  const [cancelFeeDetails, setCancelFeeDetails] =
    useState<CancelFeeCheckResponse>();

  useEffect(() => {
    if (reservationDetails && reservationDetails.reserveStatus === "COMPLETE") {
      PaymentService.cancelFeeCheck(reservationDetails.orderId)
        .then((data) => {
          setCancelFeeDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching cancel fee check details:", error);
        });
    }
  }, [reservationDetails]);

  const handleCancelClick = async () => {
    let conf = window.confirm(
      intl.formatMessage({ id: "orders.cancelConfirm" })
    );
    if (!conf) {
      return;
    }

    setIsCancelProcessing(true);

    if (reservationDetails) {
      PaymentService.cancelReservation(reservationDetails.orderId)
        .then((data) => {
          if (data.result === "SUCCESS") {
            alert(intl.formatMessage({ id: "orders.cancelComplete" }));
            dispatch(deleteMyOrders());
            UserService.getPoint().then((point) => {
              dispatch(updatePoint(point));
            });
            navigate(`/order-history`);
          } else {
            alert("주문 취소가 실패했습니다: " + data.errorMessage);
          }
        })
        .catch((error) => {
          alert("취소 과정 중 오류가 발생했습니다: " + error);
        })
        .finally(() => {
          setIsCancelProcessing(false);
        });
    }
  };

  const cancelDetails = cancelFeeDetails && (
    <CardLayout sx={{ py: 1 }}>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          "& .MuiButtonBase-root": { px: 0 },
          "& .MuiAccordionSummary-content": { my: 0 },
          width: "100%",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="common.voucherUsage"
          id="common.voucherUsage"
          sx={{
            minHeight: "36px",
            fontWeight: "bold",
          }}
        >
          <FormattedMessage id="orders.cancelButton" />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, pb: 1 }}>
          <Stack alignItems={"center"} gap={1}>
            <CancelInfoSummarySection cancelFee={cancelFeeDetails} />
            <Divider />
            <CancelTermSection />
            <CustomButton
              color="error"
              onClick={handleCancelClick}
              disabled={isCancelProcessing}
            >
              <FormattedMessage id="orders.cancelButton" />
            </CustomButton>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {isCancelProcessing && <LoadingSpinner overlap />}
    </CardLayout>
  );

  return [cancelDetails, handleCancelClick] as const;
};

export default useCancelReservation;
