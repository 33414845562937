import { useIntl } from "react-intl";

const useFormatPrice = () => {
  const intl = useIntl();
  const koreanWon: string = intl.formatMessage({ id: "payment.koreanWon" });

  const formatPrice = (price: number | string): string => {
    return `${Number(price).toLocaleString("ko-KR")} ${koreanWon}`;
  };

  return formatPrice;
};

export default useFormatPrice;
