import { Stack, useTheme } from "@mui/material";
import BottomSheet, {
  BOTTOM_SHEET_HEIGHT,
} from "components/bottomSheet/BottomSheet";
import Layout from "components/layout/Layout";
import { PaymentMethodValue } from "const/paymentMethod";
import useProcessPayment from "hooks/useProcessPayment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import PaymentService, {
  GMEPaymentRequestResponse,
} from "services/paymentService";
import TrainService from "services/trainService";
import { makeAllOptionsTrue } from "utils/consentUtils";
import { getDefaultPaymentMethod } from "utils/paymentUtils";
import history from "history/browser";
import LoadingSpinner from "components/LoadingSpinner";
import OrderStatusSection from "components/order/OrderStatusSection";
import ProductInfoSection from "pages/order-history-panel/history-detail-panel/sections/ProductInfoSection";
import RelatedProduct from "pages/order-history-panel/history-detail-panel/sections/RelatedProduct";
import PointSection from "components/payment/PointSection";
import OrderSummarySection from "components/order/OrderSummarySection";
import PaymentMethodSection from "components/payment/PaymentMethodSection";
import BookingGuidelines from "pages/booking-panel/components/BookingGuidelines";
import AgreementsSection from "pages/payment-panel/sections/AgreementsSection";
import PaymentRefundInfoSection from "pages/order-history-panel/history-detail-panel/components/PaymentRefundInfoSection";
import { PaymentModal } from "components/modal/PaymentModal";
import CustomButton from "components/button/CustomButton";
import PointParkIframe from "components/PointParkIframe";
import HanpassCoupon from "components/payment/HanpassCoupon";
import { ProviderUtils } from "utils/providerUtils";
import { OrderType } from "types/orderType";
import { isAxiosError } from "axios";
import useLanguages from "hooks/useLanguages";
import useHanpassCoupon from "hooks/useHanpassCoupon";
import useCancelReservation from "hooks/useCancelReservation";

const initialAgreements = {
  term1: false,
  term2: false,
  term3: false,
  term4: false,
};

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

interface PaymentPageProps {
  isHistory: boolean;
}

const PaymentPage = ({ isHistory }: PaymentPageProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { isKorean } = useLanguages();
  const { orderId } = useParams();

  // state
  const [isProcessing, processPayment] = useProcessPayment();
  const [reservationDetails, setReservationDetails] =
    useState<OrderType | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodValue>(
    getDefaultPaymentMethod
  );
  const [agreements, setAgreements] = useState(initialAgreements);

  // Lacha 적립금
  const [point, setPoint] = useState("0");
  const numericPoint = Number(point.replaceAll(",", ""));

  // point park
  const [pointParkUrl, setPointParkUrl] = useState("");
  const [pointParkPoint, setPointParkPoint] = useState(
    reservationDetails?.externalPointUsed || 0
  );

  // gme
  const gmeConnect = useRef<GMEPaymentRequestResponse>();

  // states for modal/bottomsheet visibility
  const [modalVisible, setModalVisible] = useState(false);
  const [bottomSheetKey, setBottomSheetKey] = useState(0);
  const [bottomSheetBottomProperty, setBottomSheetBottomProperty] = useState(
    BOTTOM_SHEET_HEIGHT * 2
  );

  // hanpass coupon
  const {
    hanpassCoupons,
    selectedCoupon,
    hanpassCouponDiscountAmount,
    setSelectedCoupon,
  } = useHanpassCoupon();

  // data
  const isCancelled = reservationDetails?.orderStatus === "CANCELED";
  const isPending = reservationDetails?.orderStatus === "PENDING";
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";

  const isPaymentProcessButtonAvailable =
    !isProcessing && paymentMethod && checkAllAgree(agreements);

  // action
  const handleAllAgree = useCallback(() => {
    setAgreements(makeAllOptionsTrue(agreements));
  }, [agreements]);

  // 결제 처리 핸들러
  const handlePayment = useCallback(async () => {
    processPayment(
      Number(orderId),
      paymentMethod,
      numericPoint,
      selectedCoupon?.memberCouponSeq
    );
  }, [
    numericPoint,
    orderId,
    paymentMethod,
    processPayment,
    selectedCoupon?.memberCouponSeq,
  ]);

  // 결제 버튼 클릭 핸들러
  const handlePaymentButtonClick = useCallback(async () => {
    if (!checkAllAgree(agreements)) {
      alert(
        isKorean
          ? "이용약관을 확인 후 동의에 체크해주세요"
          : "Please consent to the forms before proceeding"
      );
      return;
    }

    if (reservationDetails) {
      const currentDate = new Date();
      const paymentLimitDate = new Date(reservationDetails?.paymentLimitDate);

      if (currentDate > paymentLimitDate) {
        alert(
          isKorean
            ? "좌석 선점 시간이 지났습니다. 다시 예매해주세요"
            : "Your seat selection time has expired . Please book again"
        );
        navigate("/order-history");
        return;
      }
    }

    if (ProviderUtils.isPaybooc) {
      setModalVisible(true);
      return;
    }

    if (ProviderUtils.isLottecard) {
      if (reservationDetails && paymentMethod === "Credit") {
        const lottecardResponse = await PaymentService.paymentRequest(
          reservationDetails.orderId,
          paymentMethod,
          numericPoint
        );

        navigate(`/payment/process/${orderId}`, {
          state: { lottecardResponse },
        });
      } else {
        handlePayment();
      }
      return;
    }

    if (ProviderUtils.isGME) {
      if (paymentMethod === "Credit") {
        handlePayment();
        return;
      }

      if (reservationDetails) {
        const gmeResponse = await PaymentService.paymentRequest(
          reservationDetails.orderId,
          paymentMethod,
          numericPoint
        );

        gmeConnect.current = gmeResponse;
      }

      setBottomSheetBottomProperty(0);
      setBottomSheetKey((prev) => ++prev);
      return;
    }

    handlePayment();
  }, [
    agreements,
    handlePayment,
    isKorean,
    navigate,
    numericPoint,
    orderId,
    paymentMethod,
    reservationDetails,
  ]);

  const backToOrderList = useCallback(async () => {
    if (isPending && orderId) {
      await TrainService.cancelPoint(orderId);
    }
    navigate("/order-history", { replace: true });
  }, [isPending, navigate, orderId]);

  // Fetch reservation details from server
  useEffect(() => {
    if (orderId) {
      PaymentService.reserveDetail(orderId)
        .then((data) => {
          setReservationDetails(data);
          setPointParkPoint(data.externalPointUsed);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
          navigate(-1);
        });
    }
  }, [navigate, orderId]);

  useEffect(() => {
    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        backToOrderList();
      }
    });
    return historyEvent;
  }, [backToOrderList]);

  /**
   * GME App payment
   * 심플패스워드 인증 성공 시, APP에서 getCertificationResponse 함수를 호출한다.
   * GME App인 경우 getCertificationResponse 함수 생성해서 결제 로직 진행될 수 있도록 합니다.
   */
  useEffect(() => {
    if (ProviderUtils.isGME) {
      const initGMEPayment = async (key: string) => {
        try {
          // TODO: useProcessPayment로 합칠 수 있나 생각해보기
          await PaymentService.initializeGMEPayment(key);
          history.replace("/order-history");
          navigate(`/train/creditPayment?orderId=${orderId}`);
        } catch (error) {
          if (isAxiosError(error)) {
            alert("결제에 실패했습니다. 다시 시도해주세요.");
          }
        }
      };

      window.getCertificationResponse = (key: string, isCertified: boolean) => {
        initGMEPayment(key);

        return {
          certified: isCertified,
          paymentKey: key,
        };
      };
    }
  }, [navigate, orderId]);

  const calculateFinalPrice = useCallback(() => {
    if (reservationDetails) {
      return (
        reservationDetails.totalPrice -
        numericPoint -
        pointParkPoint -
        hanpassCouponDiscountAmount
      );
    }
    return 0;
  }, [
    hanpassCouponDiscountAmount,
    numericPoint,
    pointParkPoint,
    reservationDetails,
  ]);

  // cancel reservation
  const [CancelDetailsComponent, handleCancelClick] =
    useCancelReservation(reservationDetails);

  return (
    <Layout
      text={<FormattedMessage id="orders.detailTitle" />}
      onBack={backToOrderList}
    >
      {!reservationDetails ? (
        <LoadingSpinner />
      ) : (
        <Stack gap={1.5} sx={{ my: 2 }}>
          {isHistory && <OrderStatusSection order={reservationDetails} />}
          <ProductInfoSection order={reservationDetails} />
          <RelatedProduct order={reservationDetails} />

          {isPending && (
            <Stack gap={2} sx={{ textAlign: "center" }}>
              {ProviderUtils.isPointProvider && (
                <PointSection
                  point={point}
                  setPoint={setPoint}
                  priceToPay={reservationDetails.totalPrice - pointParkPoint}
                />
              )}

              {ProviderUtils.isHanpass && (
                <HanpassCoupon
                  coupons={hanpassCoupons}
                  selectedCoupon={selectedCoupon}
                  setSelectedCoupon={setSelectedCoupon}
                />
              )}

              <OrderSummarySection
                reservationDetails={reservationDetails}
                point={numericPoint}
                pointParkPoint={pointParkPoint}
                setPointParkPoint={setPointParkPoint}
                setPointParkUrl={setPointParkUrl}
                hanpassCouponDiscountAmount={hanpassCouponDiscountAmount}
              />

              {!ProviderUtils.isLottecard && (
                <PaymentMethodSection
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />
              )}

              <BookingGuidelines />

              <AgreementsSection
                agreements={agreements}
                setAgreements={setAgreements}
                handleAllAgree={handleAllAgree}
              />
            </Stack>
          )}

          {isPurchased && (
            <>
              <PaymentRefundInfoSection
                type="payment"
                order={reservationDetails}
              />
              {/* 결제 완료한 경우 취소 예상 금액 보여주기 */}
              {CancelDetailsComponent}
            </>
          )}

          {isCancelled && reservationDetails.refundInfo && (
            <PaymentRefundInfoSection
              type="refund"
              order={reservationDetails}
            />
          )}

          {/* 결제/취소 버튼 */}
          {isPending && (
            <Stack direction="row" gap={1}>
              {isHistory &&
                Number(new Date(reservationDetails.startDate)) >
                  Number(new Date()) && (
                  <CustomButton
                    id="orders.cancel"
                    variant="outlined"
                    onClick={handleCancelClick}
                    size={isPending ? "medium" : "large"}
                    color="error"
                    style={{
                      backgroundColor: theme.palette.white.main,
                    }}
                  />
                )}
              <CustomButton
                id="payment.paymentProceed"
                onClick={handlePaymentButtonClick}
                disabled={isProcessing || !isPaymentProcessButtonAvailable}
                price={calculateFinalPrice()}
                size={isPending && !isPurchased ? "medium" : "large"}
              />
            </Stack>
          )}

          {/* Paybooc modal */}
          {modalVisible && (
            <PaymentModal
              modalVisible={modalVisible}
              onClose={() => setModalVisible(false)}
            >
              <CustomButton
                id="payment.paymentProceed"
                price={calculateFinalPrice()}
                onClick={handlePayment}
                disabled={!isPaymentProcessButtonAvailable}
              />
            </PaymentModal>
          )}

          {/* GME bottom sheet */}
          {ProviderUtils.isGME && gmeConnect.current && (
            <BottomSheet
              key={bottomSheetKey}
              value={gmeConnect.current}
              bottom={bottomSheetBottomProperty}
              setBottom={setBottomSheetBottomProperty}
              reservationDetails={reservationDetails}
            />
          )}

          {/* 포인트 파크 iframe */}
          {pointParkUrl && (
            <PointParkIframe
              pointParkUrl={pointParkUrl}
              onClose={() => setPointParkUrl("")}
            />
          )}

          {isProcessing && <LoadingSpinner overlap />}
        </Stack>
      )}
    </Layout>
  );
};

export default PaymentPage;
