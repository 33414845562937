import { Box, Paper, Typography, Stack, Divider } from "@mui/material";
import Arrow from "components/Arrow";
import { formatTime } from "utils/formatUtils";
import TrainLogo from "components/TrainLogo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useIntl } from "react-intl";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomButton from "components/button/CustomButton";
import styled from "styled-components";
import { ScheduleType } from "types/scheduleType";
import { ProviderUtils } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

interface TrainInfoSectionProps {
  schedule: ScheduleType;
  handleToggleDetails: () => Promise<void>;
  isLoading: boolean;
}

const TrainInfoSection = ({
  schedule,
  handleToggleDetails,
  isLoading,
}: TrainInfoSectionProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const formatRunTime = (time: string) => {
    return `${time.substring(0, 2)}:${time.substring(2, 4)}`;
  };

  return (
    <Paper elevation={0} sx={{ width: "100%", bgcolor: "transparent" }}>
      <MediaQuery>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{ marginLeft: 1 }}
        >
          <TrainLogo stlbTrnClsfCd={schedule.stlbTrnClsfCd} />

          <Typography variant="body2">
            {schedule.trnNo} | {formatRunTime(schedule.runHm)}
          </Typography>
        </Stack>
        {schedule.gnrmRsvPsbFlg === "Y" && (
          <CustomButton
            onClick={handleToggleDetails}
            variant="text"
            size="small"
            fullWidth={false}
            disabled={isLoading}
            style={{
              padding: 0,
            }}
          >
            {isKorean
              ? ProviderUtils.isDiscountProvider
                ? intl.formatMessage({ id: "fareInquiry.inquiry" })
                : intl.formatMessage({ id: "fareInquiry.inquiryPrice" })
              : intl.formatMessage({ id: "fareInquiry.inquiry" })}
            <NavigateNextIcon fontSize="small" />
          </CustomButton>
        )}
      </MediaQuery>
      <Divider />
      <Stack alignItems="center" sx={{ my: 1.5 }}>
        <Typography variant="h5">
          <Stack direction="row" alignItems="center" gap={1}>
            {formatTime(schedule.dptTm)}
            <Arrow />
            {formatTime(schedule.arvTm)}
          </Stack>
          {/* {formatTime(schedule.dptTm)} → {formatTime(schedule.arvTm)} */}
        </Typography>
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          sx={{ position: "relative" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
            <AccessTimeIcon color="action" />
            <Typography variant="body2" sx={{ marginInlineStart: 0.5 }}>
              {intl.formatMessage({ id: "fareInquiry.economyClass" })}
              {" | "}
              {schedule.gnrmRsvPsbFlg === "Y"
                ? intl.formatMessage({ id: "fareInquiry.available" })
                : intl.formatMessage({ id: "fareInquiry.soldOut" })}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TrainInfoSection;

const MediaQuery = styled.div`
  display: flex;
  margin-bottom: 8px;

  @media screen and (min-width: 331px) {
    flex-direction: row;
    justify-content: space-between;
  }

  /* Galaxy Fold */
  @media screen and (max-width: 330px) {
    flex-direction: column;
    gap: 8px;

    & > div {
      align-self: flex-start;
    }
    & > button {
      align-self: flex-end;
    }
  }
`;
