import { useCallback, useEffect, useState } from "react";
import TabComponent from "./sections/TabComponent";
import { useNavigate } from "react-router-dom";
import Layout from "components/layout/Layout";
import { useIntl } from "react-intl";
import history from "history/browser";
import LoadingSpinner from "components/LoadingSpinner";
import TrainTicket from "../../../components/TrainTicket";
import { appendMyOrders } from "app/myOrdersSlice";
import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import useIntersect from "hooks/useIntersect";
import ScrollToTopButton from "components/ScrollToTopButton";
import UserService from "services/userService";

const OrderHistoryPage = () => {
  const dispatch = useAppDispatch();
  const { orders, filterSettings, last, number } = useAppSelector(
    (state) => state.myOrders
  );
  const intl = useIntl();
  const [isFetching, setIsFetching] = useState(false);
  const [isScrollToTopButtonShow, setIsScrollToTopButtonShow] = useState(false);
  const navigate = useNavigate();

  const infiniteScrollingTarget = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (!last && !isFetching) {
      fetchOrdersInfo();
    }
  });

  const scrollToTopButtonTarget = useIntersect(
    () => setIsScrollToTopButtonShow(false),
    () => setIsScrollToTopButtonShow(true)
  );

  const fetchOrdersInfo = useCallback(async () => {
    setIsFetching(true);

    try {
      const response = await UserService.getMyOrders(
        filterSettings?.fromDate,
        filterSettings?.toDate,
        number + 1,
        filterSettings?.status
      );

      dispatch(appendMyOrders(response));
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setIsFetching(false);
    }
  }, [
    dispatch,
    filterSettings?.fromDate,
    filterSettings?.status,
    filterSettings?.toDate,
    number,
  ]);

  // const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  // const handleFetchMoreOrders = useCallback(() => {
  //   if (!timeoutId.current) {
  //     timeoutId.current = setTimeout(() => {
  //       timeoutId.current = null;
  //       const { scrollTop, offsetHeight } = document.documentElement;
  //       if (
  //         !last &&
  //         window.visualViewport &&
  //         window.visualViewport.height + scrollTop >= offsetHeight * 0.9
  //       ) {
  //         setIsFetching(true);
  //         fetchOrdersInfo();
  //         return;
  //       }
  //       if (last) {
  //         setIsFetching(false);
  //       }
  //     }, 1000);
  //   }
  // }, [fetchOrdersInfo, last]);

  const moveToMyPage = useCallback(() => {
    navigate("/user-settings", { replace: true });
  }, [navigate]);

  useEffect(() => {
    // First order history load
    if (number === -1) {
      fetchOrdersInfo();
    }

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        moveToMyPage();
      }
    });
    return () => {
      historyEvent();
    };
  }, [dispatch, fetchOrdersInfo, moveToMyPage, number]);

  return (
    <Layout
      text={intl.formatMessage({ id: "orders.title" })}
      onBack={moveToMyPage}
    >
      <TabComponent ref={scrollToTopButtonTarget} />
      <Stack gap={1} sx={{ mb: 1 }}>
        {orders.length > 0 ? (
          orders.map((order, index) => (
            <TrainTicket key={index} order={order} />
          ))
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "50vh", width: "100%" }}
          >
            <Typography variant="h5">
              {intl.formatMessage({ id: "orders.empty" })}
            </Typography>
          </Stack>
        )}
      </Stack>
      {isFetching && orders.length > 0 && <LoadingSpinner height="20vh" />}
      {isScrollToTopButtonShow && <ScrollToTopButton />}
      <div ref={infiniteScrollingTarget}></div>
    </Layout>
  );
};

export default OrderHistoryPage;
