import { useState } from "react";
import PassengerCounter from "./sections/PassengerCounter";
import DateInformation from "./sections/DateInformation";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/material";
import { selectSearchParams, updateSearchParams } from "app/reservationSlice";
import useContinueSearching from "hooks/useContinueSearching";
import { useAppDispatch, useAppSelector } from "app/hooks";

const PassengerSelectPage = () => {
  const dispatch = useAppDispatch();
  const searchParams = useAppSelector(selectSearchParams);
  const continueSearching = useContinueSearching();

  const [adults, setAdults] = useState(searchParams?.adults ?? 1);
  const [children, setChildren] = useState(searchParams?.children ?? 0);
  const intl = useIntl();

  const handleSubmit = () => {
    // Check if at least one adult is selected
    if (adults < 1) {
      alert(intl.formatMessage({ id: "passengerSelection.noAdultError" }));
      return;
    }

    // global state에 성인과 어린이 인원 수 업데이트
    dispatch(
      updateSearchParams({
        adults,
        children,
        passengers: adults + children,
      })
    );

    // 이전 페이지로 이동
    continueSearching();
  };

  return (
    <Layout
      onBack={continueSearching}
      text={intl.formatMessage({ id: "passengerSelection.passengers" })}
    >
      <Stack gap={1} sx={{ my: 2 }} alignItems="center">
        <Stack direction="row" gap={1} sx={{ width: "100%" }}>
          <PassengerCounter
            label="adults"
            count={adults}
            onIncrement={() => setAdults(Math.min(adults + 1, 4))}
            onDecrement={() => setAdults(Math.max(adults - 1, 0))}
          />
          <PassengerCounter
            label="children"
            count={children}
            onIncrement={() => setChildren(Math.min(children + 1, 4))}
            onDecrement={() => setChildren(Math.max(children - 1, 0))}
          />
        </Stack>
        <DateInformation />
        <CustomButton
          id="passengerSelection.confirmed"
          onClick={handleSubmit}
          disabled={adults + children > 9}
        />
      </Stack>
    </Layout>
  );
};

export default PassengerSelectPage;
