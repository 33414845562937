import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@mui/material";
import parse from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import kakaoCoupon from "assets/images/kakao_coupon.webp";
import { Stack } from "@mui/system";

interface KakoAccordionProps {
  description: string;
}

const KakaoAccordion = ({ description }: KakoAccordionProps) => {
  return (
    <Accordion
      disableGutters
      sx={{
        boxShadow: "none",
        "& .MuiButtonBase-root": { px: 0 },
        "& .MuiAccordionSummary-content": { my: 0 },
        width: "100%",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="common.voucherUsage"
        id="common.voucherUsage"
        sx={{
          minHeight: "36px",
          display: "flex",
          alignItems: "end",
        }}
      >
        <FormattedMessage id="common.voucherUsage" />
      </AccordionSummary>

      <AccordionDetails>
        <Stack alignItems={"center"} gap={1}>
          <img
            src={kakaoCoupon}
            alt="kakao_coupon"
            width={"80%"}
            style={{ borderRadius: "16px" }}
            onClick={() => window.open(kakaoCoupon)}
          />
          <Divider flexItem />
          <div>{parse(`${description}`)}</div>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default KakaoAccordion;
