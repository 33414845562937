import { Typography, Chip, Stack, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { formatOrderStatus } from "utils/formatUtils";
import { useIntl } from "react-intl";
import useIntlFormatPrice from "hooks/useFormatPrice";
import useGetOrderName from "hooks/useGetOrderName";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { OrderStatus, OrderType } from "types/orderType";
import useLanguages from "hooks/useLanguages";
import CardLayout from "./layout/CardLayout";

type OrderStationSectionType = {
  order: OrderType;
};

const getChipColor = (status: OrderStatus) => {
  switch (status) {
    case "CANCELED":
      return "cancelled";
    case "PENDING":
      return "pending";
    case "COMPLETE":
    default:
      return "complete";
  }
};

const TrainTicket = ({ order }: OrderStationSectionType) => {
  // hooks
  const intl = useIntl();
  const { isKorean, isLanguage } = useLanguages();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const formatPrice = useIntlFormatPrice();
  const getOrderName = useGetOrderName();
  const formatDateTime = useFormatDateToLocaleString();

  // Actions
  // 상세보기 클릭 핸들러
  const handleDetailClick = () => {
    navigate(`/order-details/${order.orderId}`);
  };

  // 주문 상태 코드를 텍스트로 변환하는 함수
  const getOrderStatusText = (statusCode: OrderStatus) => {
    const statusMap = intl.formatMessage({ id: formatOrderStatus(statusCode) });
    return statusMap || "Unknown Status";
  };

  const getHeader = () => {
    // 주문 상태와 생성 날짜 표시
    return (
      <Stack sx={{ width: "100%" }} gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Stack
            direction={isKorean ? "row" : "column"}
            alignItems={isKorean ? "center" : "start"}
            gap={1}
          >
            <Chip
              label={getOrderStatusText(order.orderStatus)}
              color={`${getChipColor(order.orderStatus)}`}
              variant={order.orderStatus === "COMPLETE" ? "filled" : "outlined"}
              sx={{
                fontWeight: "bold",
                alignSelf: "flex-start",
              }}
            />

            {isKorean && (
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ wordBreak: "break-word" }}
              >
                {formatDateTime(order.orderCreatedDate)}
              </Typography>
            )}
          </Stack>

          {/* 상세보기 링크 */}
          <NavigateNextIcon
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={handleDetailClick}
            sx={{ alignSelf: "center" }}
          />
        </Stack>
        {!isKorean && (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ wordBreak: "break-word", alignSelf: "flex-end" }}
          >
            {formatDateTime(order.orderCreatedDate)}
          </Typography>
        )}
      </Stack>
    );
  };

  const getAdditionalInfo = () => {
    // 총 가격 표시
    return (
      <Typography
        variant={pathname.includes("order-history") ? "h6" : "body1"}
        color={
          theme.palette.mode === "light" ? "primary.main" : "primary.light"
        }
        sx={{
          width: "100%",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        {formatPrice(order.totalPrice)}
      </Typography>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      {/* 기차 아이콘과 주문 이름 */}
      <Stack
        alignItems="start"
        justifyContent="center"
        gap={0.5}
        sx={{ py: 1, width: "100%" }}
      >
        <Typography
          variant="subtitle1"
          color="text.primary"
          sx={{ position: "block", width: "100%", fontWeight: "bold" }}
        >
          {getOrderName(order.orderName)}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <Stack component="span" direction="row" alignItems="center" gap={0.8}>
            <span style={{ textAlign: "center" }}>
              {formatDateTime(order.startDate)}
            </span>
            {isLanguage("ur") ? (
              <WestIcon fontSize="inherit" />
            ) : (
              <EastIcon fontSize="inherit" />
            )}
            <span style={{ textAlign: "center" }}>
              {formatDateTime(order.endDate)}
            </span>
          </Stack>
        </Typography>
      </Stack>
    </CardLayout>
  );
};

export default TrainTicket;
