import { useIntl } from "react-intl";
import { Stack, Typography, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "styled-components";
import { CostResponse } from "services/trainService";
import DiscountIcon from "@mui/icons-material/Discount";
import SouthIcon from "@mui/icons-material/South";
import { ProviderUtils } from "utils/providerUtils";

interface CostDetailsProps {
  costDetails: CostResponse[] | null;
}

const CostDetailsSection = ({ costDetails }: CostDetailsProps) => {
  const intl = useIntl();
  const theme = useTheme();

  // data
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  );
  const childPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "3"
  );
  const isDiscount = Boolean(adultPriceDetail?.sellDscntRt);

  return (
    <MediaQueryWrapper>
      {ProviderUtils.isDiscountProvider && isDiscount && (
        <>
          <Typography
            variant="body2"
            sx={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              borderRadius: theme.shape.borderRadius,
              px: 1,
              py: 0.5,
              mb: 0.5,
              fontWeight: "bold",
            }}
          >
            {intl.formatMessage(
              { id: "fareInquiry.upTo20PercentOff" },
              { rate: adultPriceDetail?.sellDscntRt }
            )}
          </Typography>
          <Typography
            component="div"
            sx={{ fontSize: "12px", lineHeight: 1.2 }}
          >
            <Stack direction="row" gap={0.3} alignItems="center">
              <DiscountIcon sx={{ fontSize: "12px" }} />
              {intl.formatMessage({ id: "fareInquiry.standardPrice" })}
            </Stack>
          </Typography>
        </>
      )}

      <Stack
        direction="row"
        gap={0.5}
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        <Typography component="span" variant="body2">
          {
            intl
              .formatMessage(
                { id: "fareInquiry.adultPrice" },
                { price: adultPriceDetail?.csmrFare.toLocaleString() }
              )
              .split(":")[0]
          }
          :{" "}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography
            component="span"
            variant="body2"
            sx={{
              textDecoration:
                ProviderUtils.isDiscountProvider && isDiscount
                  ? "line-through"
                  : "none",
              textDecorationColor: theme.palette.error.main,
            }}
          >
            {
              intl
                .formatMessage(
                  { id: "fareInquiry.adultPrice" },
                  { price: adultPriceDetail?.csmrFare.toLocaleString() }
                )
                .split(":")[1]
            }
          </Typography>
          {ProviderUtils.isDiscountProvider && isDiscount && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                component="span"
                variant="body2"
                sx={{
                  color: theme.palette.error.main,
                }}
              >
                {adultPriceDetail?.sellDscntRt}%
              </Typography>
              <SouthIcon
                fontSize="small"
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: theme.palette.error.main,
                }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      <Typography
        component="span"
        variant="body2"
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        {intl.formatMessage(
          { id: "fareInquiry.childPrice" },
          { price: childPriceDetail?.mrkFare.toLocaleString() }
        )}
      </Typography>
    </MediaQueryWrapper>
  );
};

export default CostDetailsSection;

const MediaQueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 425px) {
    align-items: center;
  }
`;
