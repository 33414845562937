import { useAppDispatch } from "app/hooks";
import { fetchUser } from "app/userSlice";
import axios, { isAxiosError } from "axios";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import TokenService from "services/tokenService";
import UserService from "services/userService";
import { ProviderUtils } from 'utils/providerUtils';
import { StorageUtils } from "utils/storageUtils";

interface UserInfo {
  data: {
    kid: string;
    email: string;
    name: string;
  };
}

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_REDIRECT_URI = `https://${ProviderUtils.provider ?? ""}${ProviderUtils.provider ? "." : ""}${
  process.env.REACT_APP_GOOGLE_REDIRECT_URI
}`;
const GOOGLE_TOKEN_URI = "https://oauth2.googleapis.com/token";
const GOOGLE_USER_URI = "https://oauth2.googleapis.com/tokeninfo";

const params = {
  // code,
  client_id: GOOGLE_CLIENT_ID!,
  client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET!,
  redirect_uri: GOOGLE_REDIRECT_URI,
  grant_type: "authorization_code",
};

const GoogleCallback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code")!;
  const isModal = StorageUtils.IsLoginModal.get();

  const userInfoRef = useRef<UserInfo>();

  useEffect(() => {
    const getToken = async () => {
      try {
        const tokenResponse = await axios.post(GOOGLE_TOKEN_URI, null, {
          params: { ...params, code },
        });

        const userResponse = await axios.get(
          `${GOOGLE_USER_URI}?id_token=${tokenResponse.data.id_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.data.access_token}`,
            },
          }
        );
        userInfoRef.current = userResponse;
      } catch (error) {
        if (isAxiosError(error)) {
          console.error(error.message);
        }
        alert("Google signin failed");
        return;
      }

      try {
        const response = await AuthService.googleLogin(
          String(userInfoRef.current?.data.kid)
        );

        if (response.status < 300) {
          TokenService.setAccessToken(response.data.accessToken);

          // 사용자 정보
          const userInfoResponse = await UserService.getMyInfo();
          dispatch(fetchUser(userInfoResponse));

          if (isModal) {
            navigate("/search-results");
            return;
          }
          navigate("/");
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const userInfo = { ...error.response?.data.userInfo };
          userInfo.email = userInfoRef.current?.data.email;
          userInfo.firstName = userInfoRef.current?.data.name;
          navigate("/auth/additional-info", {
            state: { userInfo, ssoName: "GOOGLE" },
          });
        }
      }
    };

    getToken();
  });

  return <LoadingSpinner />;
};

export default GoogleCallback;
